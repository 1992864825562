import React from "react";

const FormError = (props: FormErrorProps) => {
    return (
        <React.Fragment>
            <h6 className="red-text pl-2 mb-0 mt-1">{props.errorMessage}</h6>
        </React.Fragment>
    );
};

export default FormError;

interface FormErrorProps {
    errorMessage: string;
}
