import React, {useEffect} from "react";
import {GameStep, RunningGameStatus} from "../../../../../api";
import PendingStartScreen from "./PendingStart/PendingStartScreen";
import {capitalizeFirstLetter} from "../../../../../utils/textUtils";
import InRoundScreen from "./InRound/InRoundScreen";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";
import FormActionContainer from "../../../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/VIButton";
import {useModal} from "../../../../../modules/modal/hooks/useModal";
import Modal from "../../../../../modules/modal/components/Modal";
import {ModalSize} from "../../../../../modules/modal/helpers/modalHelpers";
import FormRowWithText from "../../../../Form/FormRowWithText";
import NotInRoundScreen from "./InRound/NotInRoundScreen";
import BetweenRoundsScreen from "./BetweenRounds/BetweenRoundsScreen";
import {useDispatch} from "react-redux";
import {nullifyGameStore} from "../../../../../store/game/actions/GameActions";
import PendingStartScreenNotInNextRound from "./PendingStart/PendingStartScreenNotInNextRound";
import PlayerCompleteGame from "./CompleteGame/PlayerCompleteGame";
import {nullifyUserStore} from "../../../../../store/user/actions/UserActions";

const PlayerGameWrapper = (props: RunningGameStatus) => {
    const history = useHistory();
    const {isShown, toggle} = useModal();
    const dispatch = useDispatch();

    const goBackToLoginScreen = () => {
        history.push(routeNames.userLogin.path);
        dispatch(nullifyUserStore());
    };

    useEffect(() => {
        return () => {
            dispatch(nullifyGameStore());
        };
    }, []);

    return (
        <React.Fragment>
            <div className="pt-4 pb-4" />
            {capitalizeFirstLetter(props.step) === GameStep.PendingStart && (
                <React.Fragment>
                    {props.round + 1 < props.companies[0].startingRound ? (
                        <PendingStartScreenNotInNextRound {...props} />
                    ) : (
                        <PendingStartScreen {...props} />
                    )}
                </React.Fragment>
            )}
            {capitalizeFirstLetter(props.step) === GameStep.InRound && (
                <React.Fragment>
                    {props.round < props.companies[0].startingRound ? (
                        <NotInRoundScreen {...props} />
                    ) : (
                        <InRoundScreen {...props} />
                    )}
                </React.Fragment>
            )}
            {capitalizeFirstLetter(props.step) === GameStep.BetweenRounds && (
                <React.Fragment>
                    {props.round < props.companies[0].startingRound ? (
                        <NotInRoundScreen {...props} />
                    ) : (
                        <BetweenRoundsScreen {...props} />
                    )}
                </React.Fragment>
            )}
            {capitalizeFirstLetter(props.step) === GameStep.Complete && (
                <PlayerCompleteGame {...props} />
            )}
            <div className="pt-4 pb-4" />
            <FormActionContainer rowClassName={"ml-0 pb-3 mr-0"}>
                <div className="col text-center">
                    <VIButton
                        roundedCorner
                        size={ButtonSize.Large}
                        innerValue={"Leave Session"}
                        onClick={toggle}
                        colour={ButtonColourOptions.Orange}
                    />
                </div>
            </FormActionContainer>
            <div className="pt-4 pb-4" />
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={"Leave Session"}
                modalSize={ModalSize.extra_small}
            >
                <React.Fragment>
                    <FormRowWithText rowName={"Are you sure you want to leave the session?"} />
                    <FormActionContainer>
                        <div className="col mt-3">
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={goBackToLoginScreen}
                                colour={ButtonColourOptions.Orange}
                            />
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </FormActionContainer>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default PlayerGameWrapper;
