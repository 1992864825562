import React from "react";
import {Game, GamePerRound} from "../../../../../../api";
import FormHeader from "../../../../../Form/FormHeader";
import GamePerRoundSettingsHeader from "./GamePerRoundSettingsHeader";
import GamePerRoundSettingsItem from "./GamePerRoundSettingItem";

const GamePerRoundSettings = (props: Game) => {
    return (
        <React.Fragment>
            <FormHeader
                headerName={"Time Per Round Settings"}
                headerType={"h5"}
                rowClassName="mt-3"
                showRow
            />
            <React.Fragment>
                <div className="m-3">
                    <div className="d-block table-overflow-x vi-table-wrapper">
                        <table className="vi-table fadeIn">
                            <GamePerRoundSettingsHeader />
                            <tbody>
                                {props.roundConfigs.map((item: GamePerRound, index) => {
                                    return <GamePerRoundSettingsItem {...item} key={index} />;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        </React.Fragment>
    );
};

export default GamePerRoundSettings;
