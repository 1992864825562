import React from "react";
import {RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import CompleteRoundTable from "../Table/CompleteRoundTable";
import {getCompanyBidsForGame, getCompanyNames} from "../DataTables/DataTables";
import CompleteRoundTableRowWrapper from "../Table/CompleteRoundTableRowWrapper";
import {bankersRound} from "../../../../../../../utils/mathUtils";

const RankingTables = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={"Ranking Tables"}
                headerType={"h2"}
                showRow
            />
            <CompleteRoundTable
                headerType={"Ranking"}
                headerName={"Average Marketing Profit"}
                extraColumnName={"Average Profit Per Round"}
            >
                {getMarketingProfit(props, "Average")}
            </CompleteRoundTable>
            <CompleteRoundTable
                headerType={"Ranking"}
                headerName={"Total Marketing Profit"}
                extraColumnName={"Total Profit"}
            >
                {getMarketingProfit(props, "Total")}
            </CompleteRoundTable>
        </React.Fragment>
    );
};

export default RankingTables;

function getMarketingProfit(props: RunningGameStatus, sortType: MarketSortType) {
    const companies = getCompanyNames(props.companies);
    const rows: CompanyMarketProfit[] = [];

    for (const company of companies) {
        const sortedRow = getCompanyBidsForGame(company, props.rounds);
        const total = sortedRow.reduce((a, b) => a + b.value, 0);
        rows.push({
            companyName: company,
            average: total / props.rounds.length,
            total
        });
    }

    return sortRowsBySortType(rows, sortType).map((item, index) => {
        return generateTdsForRow(`Rank ${index + 1}`, item, sortType);
    });
}

export function sortRowsBySortType(rows: CompanyMarketProfit[], sortType: MarketSortType) {
    switch (sortType) {
        case "Average":
            return rows.sort((a, b) => b.average - a.average);
        case "Total":
            return rows.sort((a, b) => b.total - a.total);
    }
}

export interface CompanyMarketProfit {
    companyName: string;
    average: number;
    total: number;
}

export type MarketSortType = "Total" | "Average";

/** TABLE STUFF */
function generateTdsForRow(
    rowName: string,
    item: CompanyMarketProfit,
    sortType: MarketSortType
): JSX.Element {
    return (
        <CompleteRoundTableRowWrapper>
            <td align="left" className="vi-table-row-item-item p-3">
                {rowName}
            </td>
            <td align="left" className="vi-table-row-item-item p-3">
                {item.companyName}
            </td>
            <td align="left" className="vi-table-row-item-item p-3">
                {bankersRound(sortType === "Total" ? item.total : item.average)}
            </td>
        </CompleteRoundTableRowWrapper>
    );
}
