import React from "react";
import {RunningGameStatus} from "../../../../../../api";
import FormWrapper from "../../../../../Form/FormWrapper";
import FormHeader from "../../../../../Form/FormHeader";
import CustomerNextRoundInfo from "../../../Shared/Components/CustomerNextRoundInfo/CustomerNextRoundInfo";
import CompanyRoundInfo from "../../../Shared/Components/CompanyRoundInfo/CompanyRoundInfo";
import ClinicalUnitRequirementTable from "../../../Shared/Components/CustomerNextRoundInfo/Table/ClinicalUnitRequirementTable";
import SharedGameInformation from "../Shared/SharedGameInformation";

const PendingStartScreen = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormWrapper>
                <FormHeader
                    rowClassName={"mt-3"}
                    headerName={`Welcome ${props.companies[0].name}`}
                    headerType={"h2"}
                    showRow
                />
                <div className="row ml-3 mr-3 pt-3">
                    <p className="dark-blue-text">
                        The game has not started yet! Whilst you wait, here are details about the
                        game!
                    </p>
                </div>
                <SharedGameInformation {...props} />
                <CompanyRoundInfo runningGameStatus={props} isCurrentRound={false} />
                <ClinicalUnitRequirementTable {...props} />
                <CustomerNextRoundInfo gameStatus={props} showNextRoundText={true} />
                <div className="row ml-3 mr-3 pt-3">
                    <p className="dark-blue-text">
                        Feel free to ask questions! Otherwise, have fun!
                    </p>
                </div>
            </FormWrapper>
        </React.Fragment>
    );
};

export default PendingStartScreen;
