import React, {useEffect, useState} from "react";
import {CurrentTab, TabsProps} from "../../Helpers/gameSettingsHelpers";
import {Customer, GameStep} from "../../../../../api";
import ActiveCustomerTab from "./ActiveCustomerTab";
import {toArray} from "../../../../../utils/ArrayUtils";
import {validateCustomer} from "../../../../../store/gameSettings/helpers/gameSettingsHelpers";
import Tooltip from "../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../Icon/Icon";
import {useDispatch} from "react-redux";
import {useModal} from "../../../../../modules/modal/hooks/useModal";
import Modal from "../../../../../modules/modal/components/Modal";
import {ModalSize} from "../../../../../modules/modal/helpers/modalHelpers";
import {
    addCustomerSettings,
    deleteCustomerSettings
} from "../../../../../store/gameSettings/actions/GameSettingsActions";
import FormRowWithText from "../../../../Form/FormRowWithText";
import FormActionContainer from "../../../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/VIButton";
import {useGameState} from "../../../../Hooks/useGameState";

const CustomerTabs = (props: Customer[]) => {
    const [tabsProps, setTabsProps] = useState<TabsProps<Customer>>();
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();
    const [companyIndex, setCompanyIndex] = useState<number>(-1);
    const gameState = useGameState();

    useEffect(() => {
        const tabIndex = tabsProps?.currentTab ? tabsProps.currentTab.tabIndex : 0;
        const tabs: Customer[] = toArray(props);
        const currentTab: CurrentTab<Customer> = {
            tab: tabs[tabIndex] ? tabs[tabIndex] : tabs[0],
            tabIndex: tabs[tabIndex] ? tabIndex : 0
        };
        setTabsProps({tabs, currentTab});
    }, [props]);

    const handleSelectTab = (tab: Customer, tabIndex: number) => {
        if (!tabsProps) return;

        setTabsProps({
            ...tabsProps,
            currentTab: {
                tab,
                tabIndex
            }
        });
    };

    return (
        <React.Fragment>
            <ul className="nav nav-tab ml-3 mr-3 mt-3">
                {tabsProps &&
                    tabsProps.tabs.map((item: Customer, index: number) => {
                        return (
                            <li
                                key={index}
                                onClick={() => handleSelectTab(item, index)}
                                className={
                                    tabsProps.currentTab.tabIndex === index ? "tab active" : "tab"
                                }
                            >
                                <div className="row ml-0 mr-0">
                                    <p className="mb-0">
                                        {item.name}{" "}
                                        {!validateCustomer(item, false, toArray(props)).valid && (
                                            <Tooltip
                                                tooltipText={"One or more fields are invalid"}
                                                size={"lg"}
                                                place={"bottom"}
                                                rootElement={"span"}
                                            >
                                                <Icon
                                                    rootElement={"Span"}
                                                    icon={IconType.Error}
                                                    size={"Large"}
                                                    className="icon-red"
                                                />
                                            </Tooltip>
                                        )}
                                        {gameState === GameStep.PendingStart && (
                                            <Tooltip
                                                tooltipText={"Delete Customer"}
                                                size={"lg"}
                                                place={"bottom"}
                                                rootElement={"span"}
                                            >
                                                <Icon
                                                    rootElement={"Span"}
                                                    icon={IconType.Bin}
                                                    size={"Large"}
                                                    className="icon-dark"
                                                    onClick={() => {
                                                        setCompanyIndex(index);
                                                        toggle();
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </p>
                                </div>
                            </li>
                        );
                    })}
                {gameState === GameStep.PendingStart && (
                    <li className="tab" onClick={() => dispatch(addCustomerSettings())}>
                        <Icon
                            rootElement={"Div"}
                            icon={IconType.Add}
                            size={"Large"}
                            className="icon-dark mt-2"
                        />
                    </li>
                )}
            </ul>
            {tabsProps && tabsProps.tabs.length > 0 && (
                <ActiveCustomerTab {...tabsProps.currentTab} />
            )}
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={"Remove Company"}
                modalSize={ModalSize.extra_small}
            >
                <React.Fragment>
                    <FormRowWithText rowName={"Are you sure you want to delete this setting?"} />
                    <FormActionContainer>
                        <div className="col mt-3">
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={() => {
                                    dispatch(deleteCustomerSettings(companyIndex));
                                    toggle();
                                }}
                                colour={ButtonColourOptions.Orange}
                            />
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={() => {
                                    setCompanyIndex(-1);
                                    toggle();
                                }}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </FormActionContainer>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default CustomerTabs;
