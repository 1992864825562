import store from "../Store";
import history from "../../components/Navigation/Routes/history";
import {routeNames} from "../../components/Navigation/routeNames";
import {StatusCode4xx} from "../../modules/storeFetchWrappers/store/helpers/utils/StoreFetchWrappers";
export interface ApiPluginOptions {
    basePath: string | undefined;
}

export function getAdminHeader() {
    const base64encodedData = localStorage.getItem("basic_auth_creds");
    if (!base64encodedData) {
        history.replace(routeNames.fourOhOne.path);
        return;
    }
    return {
        headers: {
            Authorization: "Basic " + base64encodedData
        }
    };
}

export function getUserHeader() {
    const base64encodedData = store.getState().user.data;
    if (!base64encodedData) {
        history.replace(routeNames.fourOhOne.path);
        return;
    }

    return {
        headers: {
            Authorization: "Basic " + base64encodedData
        }
    };
}

export function getEncodedToken(email: string, password?: string) {
    return btoa(unescape(encodeURIComponent(email + ":" + password)));
}

export function statusCodeCallback(code: StatusCode4xx) {
    switch (code) {
        case StatusCode4xx.FourZeroFour:
            history.replace(routeNames.fourOhFour.path);
            return;
        case StatusCode4xx.FourZeroOne:
            history.replace(routeNames.fourOhOne.path);
            return;
        case StatusCode4xx.FourZeroThree:
            history.replace(routeNames.fourOhThree.path);
            return;
    }
}
