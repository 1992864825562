import React from "react";
import {Router} from "react-router-dom";
import history from "./components/Navigation/Routes/history";
import Routes from "./components/Navigation/Routes/Routes";
import store from "./store/Store";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import axios from "axios";
import {routeNames} from "./components/Navigation/routeNames";

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <Routes />
            </Router>
            <ToastContainer
                position="bottom-center"
                pauseOnHover
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                draggable
                autoClose={5000}
            />
        </Provider>
    );
}

export default App;

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const status = error.response ? error.response.status : null;

        /*if (status === 400) {
            //showErrorToast(error.response.data.message);
        }*/
        if (status === 401) {
            history.replace(routeNames.fourOhOne.path);
            throw error;
        }
        if (status === 403) {
            history.replace(routeNames.fourOhThree.path);
            throw error;
        }
        if (status === 404) {
            history.replace(routeNames.fourOhFour.path);
            throw error;
        }

        throw error;
    }
);
