import {AxiosPromise, AxiosResponse} from "axios";
import {Dispatch} from "redux";
import {StoreState} from "../types/StoreServiceData";

/**
 * Error returned by web server on 500s
 */
type ErrorResponse = {
    message?: string | null;
};

/**
 * Shape of an error thrown by Axios
 */
type AxiosError = {
    message?: string | null;
    response?: AxiosResponse<ErrorResponse | string> | null;
};

/** for DELETE requests. This variation will return data without going through the store. Error and Loading states
 * will be updated in the store */
export async function deleteDataFromServiceWithData<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    deleteCall: () => AxiosPromise<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<T | undefined | null> {
    try {
        const result = await deleteCall();
        //Check for result, sometimes, result can be null, indicates 5xx error
        if (result) {
            return handlePostDeleteActionWithData(storeState, dispatch, result, statusCodeCallback);
        }

        return;
    } catch (error: any) {
        handleStatusCode(error.response.status, statusCodeCallback);
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return;
    }
}

/** for DELETE requests. This variation will dispatch data to the store. Error and Loading states
 * will be updated in the store */
export async function deleteDataFromServiceWithRedux<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    deleteCall: () => AxiosPromise<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<boolean> {
    try {
        const result = await deleteCall();
        //Check for result, sometimes, result can be null, indicates 5xx error
        if (result) {
            return handlePostDeleteActionWithRedux(
                storeState,
                dispatch,
                result,
                statusCodeCallback
            );
        }

        return false;
    } catch (error: any) {
        handleStatusCode(error.response.status, statusCodeCallback);
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return false;
    }
}

/** for GET requests. This variation will dispatch data to the store. Error and Loading states
 * will be updated in the store */
export async function getDataFromServiceWithRedux<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    fetchCall: () => AxiosPromise<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<boolean> {
    try {
        const result = await fetchCall();
        //Check for result, sometimes, result can be null, indicates 5xx error
        if (result) {
            return handleGetActionWithRedux(storeState, dispatch, result, statusCodeCallback);
        }

        return false;
    } catch (error: any) {
        handleStatusCode(error.response.status, statusCodeCallback);
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return false;
    }
}

async function handleGetActionWithRedux<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    response: AxiosResponse<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<boolean> {
    try {
        const hasHit4xxRoutes = check4xxRoutes(storeState, dispatch, response, statusCodeCallback);

        if (!hasHit4xxRoutes) return false;

        if (response.status === 200) {
            dispatch({
                type: storeState.SUCCESS,
                loading: false,
                error: null,
                data: response.data
            });
            return true;
        }

        return false;
    } catch (error: any) {
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return false;
    }
}

/** for GET requests. This variation will return data without dispatching it to the store. Error and Loading states
 * will be updated in the store */

export async function getDataFromServiceWithData<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    fetchCall: () => AxiosPromise<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<T | undefined | null> {
    try {
        const result = await fetchCall();
        //Check for result, sometimes, result can be null, indicates 5xx error
        if (result) {
            return handleGetActionWithData(storeState, dispatch, result, statusCodeCallback);
        }

        return;
    } catch (error: any) {
        handleStatusCode(error.response.status, statusCodeCallback);
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return;
    }
}

async function handleGetActionWithData<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    response: AxiosResponse<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<T | undefined | null> {
    try {
        const hasHit4xxRoutes = check4xxRoutes(storeState, dispatch, response, statusCodeCallback);

        if (!hasHit4xxRoutes) return;

        if (response.status === 200) {
            return response.data;
        }

        return;
    } catch (error: any) {
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return;
    }
}

/** for POST requests. This variation will dispatch data to the store. Error and Loading states
 * will be updated in the store */
export async function postDataToServiceWithRedux<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    postCall: () => AxiosPromise<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<boolean> {
    try {
        const result = await postCall();
        //Check for result, sometimes, result can be null, indicates 5xx error
        if (result) {
            return handlePostDeleteActionWithRedux(
                storeState,
                dispatch,
                result,
                statusCodeCallback
            );
        }
        return false;
    } catch (error: any) {
        handleStatusCode(error.response.status, statusCodeCallback);
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return false;
    }
}

async function handlePostDeleteActionWithRedux<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    response: AxiosResponse<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<boolean> {
    try {
        const hasHit4xxRoutes = check4xxRoutes(storeState, dispatch, response, statusCodeCallback);

        if (!hasHit4xxRoutes) return false;

        if (response.status === 200 || response.status === 204) {
            dispatch({
                type: storeState.SUCCESS,
                loading: false,
                error: null,
                data: response.data
            });
            return true;
        }

        return false;
    } catch (error: any) {
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return false;
    }
}

/** for POST requests. This variation will return data without dispatching it to the store. Error and Loading states
 * will be updated in the store */
export async function postDataToServiceWithData<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    postCall: () => AxiosPromise<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<T | undefined | null> {
    try {
        const result = await postCall();
        //Check for result, sometimes, result can be null, indicates 5xx error
        if (result) {
            return handlePostDeleteActionWithData(storeState, dispatch, result, statusCodeCallback);
        }
    } catch (error: any) {
        handleStatusCode(error.response.status, statusCodeCallback);
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return;
    }
}

async function handlePostDeleteActionWithData<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    response: AxiosResponse<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<T | undefined | null> {
    try {
        const hasHit4xxRoutes = check4xxRoutes(storeState, dispatch, response, statusCodeCallback);

        if (!hasHit4xxRoutes) return;

        if (response.status === 200 || response.status === 204) {
            return response.data;
        }
        return;
    } catch (error: any) {
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return;
    }
}

async function check4xxRoutes<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    response: AxiosResponse<T>,
    statusCodeCallback: (code: StatusCode4xx) => void
): Promise<boolean> {
    try {
        if (response.status === 400) {
            dispatch({
                type: storeState.ERROR,
                loading: false,
                error: "Validation Error"
            });
            statusCodeCallback(StatusCode4xx.FourZeroZero);
            return false;
        }

        if (response.status === 401) {
            dispatch({
                type: storeState.ERROR,
                loading: false,
                error: "Not Authenticated"
            });
            statusCodeCallback(StatusCode4xx.FourZeroOne);
            return false;
        }

        if (response.status === 403) {
            dispatch({
                type: storeState.ERROR,
                loading: false,
                error: "Not Authorized"
            });
            statusCodeCallback(StatusCode4xx.FourZeroThree);
            return false;
        }

        if (response.status === 404) {
            dispatch({
                type: storeState.ERROR,
                loading: false,
                error: "Resource not found"
            });
            statusCodeCallback(StatusCode4xx.FourZeroFour);
            return false;
        }

        return true;
    } catch (error: any) {
        dispatch({
            type: storeState.ERROR,
            loading: false,
            error: error.response.data.message
        });
        return false;
    }
}

function handleStatusCode(
    code: number | undefined,
    statusCodeAction: (newCode: StatusCode4xx) => void
): void {
    if (!code) return;
    switch (code) {
        case 401:
            statusCodeAction(StatusCode4xx.FourZeroOne);
            return;
        case 403:
            statusCodeAction(StatusCode4xx.FourZeroThree);
            return;
        case 404:
            statusCodeAction(StatusCode4xx.FourZeroFour);
            return;
    }
}

// eslint-disable-next-line no-shadow
export enum StatusCode4xx {
    FourZeroZero = "FourZeroZero",
    FourZeroOne = "FourZeroOne",
    FourZeroThree = "FourZeroThree",
    FourZeroFour = "FourZeroFour"
}
