import React from "react";
import {Bid} from "../../../../../../api";
import {RoundTableBase} from "./Helpers/roundTableHelpers";

const RoundTableRow = (props: RoundTableRowProps) => {
    return (
        <React.Fragment>
            <tr className="vi-table-row">
                {props.showRankColumn && (
                    <td align="left" className="vi-table-row-item-item p-3">
                        {props.bid.rank}
                    </td>
                )}
                {props.showCompanyColumn && (
                    <td align="left" className="vi-table-row-item-item p-3">
                        {props.bid.companyName}
                    </td>
                )}
                {props.showCustomerColumn && (
                    <td align="left" className="vi-table-row-item-item p-3">
                        {props.bid.customerName}
                    </td>
                )}
                <td align="left" className="vi-table-row-item-item p-3">
                    {props.bid.packsDelivered}
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    {props.bid.bid}
                </td>
                {!props.showRankColumn && (
                    <td align="left" className="vi-table-row-item-item p-3">
                        {props.bid.success ? "Yes" : "No"}
                    </td>
                )}
                {props.showPenaltyColumn && (
                    <td align="left" className="vi-table-row-item-item p-3">
                        {props.bid.penalty}
                    </td>
                )}
                <td align="left" className="vi-table-row-item-item p-3">
                    {props.bid.costs}
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    {props.bid.revenue}
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    {props.bid.profit}
                </td>
                {props.showMaxRevenueColumn && (
                    <td align="left" className="vi-table-row-item-item p-3">
                        {props.bid.maxPotentialRevenue}
                    </td>
                )}
                {props.showMaxProfitColumn && (
                    <td align="left" className="vi-table-row-item-item p-3">
                        {props.bid.maxPotentialProfit}
                    </td>
                )}
            </tr>
        </React.Fragment>
    );
};

export default RoundTableRow;

interface RoundTableRowProps extends RoundTableBase {
    bid: Bid;
}
