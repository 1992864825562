import React, {useMemo} from "react";
import {GameStep, RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import {getPreviousRoundTables, RoundTable} from "./helpers/previousRoundHelpers";
import PreviousRoundTable from "./PreviousRoundTable";
import PreviousRoundStats from "./PreviousRoundStats";
import {capitalizeFirstLetter} from "../../../../../../../utils/textUtils";

const PreviousRoundViews = (props: RunningGameStatus) => {
    const memoizedRoundTables = useMemo(
        () => getPreviousRoundTables(props.customers, props.rounds, props.round, props.step),
        [props.customers, props.rounds, props.step]
    );

    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Previous Round Information:`}
                headerType={"h2"}
                showRow
            />
            {memoizedRoundTables.map((roundTable: RoundTable, index) => {
                return (
                    <React.Fragment key={index}>
                        <PreviousRoundTable
                            {...roundTable}
                            showHeader={true}
                            showPenaltyColumn={true}
                            showRankColumn={true}
                            showCustomerColumn={false}
                            showCompanyColumn={true}
                            showMaxProfitColumn={
                                capitalizeFirstLetter(props.step) === GameStep.Complete
                            }
                            showMaxRevenueColumn={
                                capitalizeFirstLetter(props.step) === GameStep.Complete
                            }
                        />
                        <PreviousRoundStats
                            marketShareType={"Value"}
                            status={props}
                            index={memoizedRoundTables.length - index}
                        />
                        <PreviousRoundStats
                            marketShareType={"Volume"}
                            status={props}
                            index={memoizedRoundTables.length - index}
                        />
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default PreviousRoundViews;
