import React from "react";
import {CompanyPerRound, RunningGameStatus} from "../../../../../../api";
import FormHeader from "../../../../../Form/FormHeader";
import FormRow from "../../../../../Form/FormRow";

const CompanyRoundInfo = (props: CompanyRoundInfoProps) => {
    const companyPerRound = getCompanyRound(props);

    const getSectionHeader = () => {
        const {runningGameStatus} = props;
        const companyName = runningGameStatus.companies[0].name;
        if (props.isCurrentRound) {
            return `${companyName} Current Round Details (Round ${runningGameStatus.round})`;
        }

        return `${companyName} Next Round Details (Round ${runningGameStatus.round + 1})`;
    };
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={getSectionHeader()}
                headerType={"h5"}
                showRow
            />
            <FormRow
                rowName={"Sourcing Limit:"}
                formRowClassName={"ml-3 mt-3"}
                columnClassName="pr-3"
            >
                {companyPerRound && (
                    <p className="dark-blue-text overflow-wrap">{companyPerRound.sourcingLimit}</p>
                )}
            </FormRow>
            <FormRow
                rowName={"Cost of goods:"}
                formRowClassName={"ml-3 mt-3"}
                columnClassName="pr-3"
            >
                {companyPerRound && (
                    <p className="dark-blue-text overflow-wrap">{companyPerRound.costPerPack}</p>
                )}
            </FormRow>
        </React.Fragment>
    );
};

export default CompanyRoundInfo;

interface CompanyRoundInfoProps {
    isCurrentRound: boolean;
    runningGameStatus: RunningGameStatus;
}

function getCompanyRound(args: CompanyRoundInfoProps): CompanyPerRound | undefined {
    const roundToGet = args.isCurrentRound
        ? args.runningGameStatus.round
        : args.runningGameStatus.round + 1;
    const index = args.runningGameStatus.companies[0].rounds.findIndex(
        (item: CompanyPerRound) => item.round === roundToGet
    );

    if (index < 0) return;

    return args.runningGameStatus.companies[0].rounds[index];
}
