import React from "react";

const CompleteRoundTableRowWrapper = (props: CompleteRoundTableRowWrapperProps) => {
    return (
        <React.Fragment>
            <tr className="vi-table-row">{props.children}</tr>
        </React.Fragment>
    );
};

export default CompleteRoundTableRowWrapper;

interface CompleteRoundTableRowWrapperProps {
    children: any;
}
