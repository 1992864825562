import React from "react";
import {Company} from "../../../../../api";
import FormWrapper from "../../../../Form/FormWrapper";
import FormHeader from "../../../../Form/FormHeader";

const AccessCode = (props: Company) => {
    return (
        <React.Fragment>
            {props.accessCode && (
                <React.Fragment>
                    <FormWrapper>
                        <FormHeader
                            headerName={`${props.name} Access Code`}
                            headerType={"h4"}
                            showRow
                            textAlign={"center"}
                        />
                        <FormHeader
                            headerName={`${props.accessCode}`}
                            rowClassName="dark-blue-text"
                            headerType={"h5"}
                            textAlign={"center"}
                        />
                    </FormWrapper>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default AccessCode;
