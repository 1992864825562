import {
    Company,
    CompanyPerRound,
    Customer,
    CustomerPerRound,
    Game,
    GamePerRound,
    GameStep,
    PerceivedValueForCompany
} from "../../../api";
import {FormErrors} from "../../../utils/formUtils";
import {showErrorToast} from "../../../utils/toastUtils";

export function generateNewSettings(
    numberCompanies: number,
    numberRounds: number,
    numberCustomers: number
): Game {
    const companies = generateCompanies(numberCompanies, numberRounds);
    const defaultTime = 20;
    return {
        id: 0,
        version: 1,
        name: "",
        description: "",
        dateCreated: 0,
        numberRounds,
        timePerRoundMins: defaultTime,
        roundConfigs: createBlankGamePerRound(numberRounds, defaultTime),
        clinicalUnitName: "Pack",
        customers: generateCustomers(numberCustomers, companies, numberRounds),
        companies,
        state: {
            round: 0,
            step: GameStep.PendingStart,
            rounds: []
        }
    };
}

export function generateCompanies(numberCompanies: number, numberRounds: number): Company[] {
    const companies: Company[] = [];

    for (let i = 0; i < numberCompanies; ++i) {
        companies.push(createBlankCompany(i, numberRounds));
    }

    return companies;
}

export function createBlankCompany(index: number, numberRound: number): Company {
    return {
        name: `Company ${index + 1}`,
        description: "",
        baseSourcingLimit: 10000,
        sourcingLimitRate: 1,
        baseCostPerPack: 10,
        costPerPackRate: 10,
        baseClinicalUnitsPerPack: 1,
        startingRound: 1,
        accessCode: undefined,
        rounds: createBlankCompanyPerRound(numberRound)
    };
}

export function createBlankGamePerRound(
    numberRounds: number,
    defaultValue: number
): GamePerRound[] {
    const gamePerRounds: GamePerRound[] = [];
    for (let i = 0; i < numberRounds; ++i) {
        gamePerRounds.push({
            round: i + 1,
            roundTimeMins: defaultValue
        });
    }

    return gamePerRounds;
}

export function createBlankCompanyPerRound(numberRounds: number): CompanyPerRound[] {
    const companyPerRound: CompanyPerRound[] = [];

    for (let i = 0; i < numberRounds; ++i) {
        companyPerRound.push({
            round: i + 1,
            sourcingLimit: 1000,
            costPerPack: 10,
            clinicalUnitsPerPack: 1
        });
    }

    return companyPerRound;
}

export function generateCustomers(
    amount: number,
    companies: Company[],
    numberRounds: number
): Customer[] {
    const customers: Customer[] = [];

    for (let i = 0; i < amount; ++i) {
        customers.push(createBlankCustomer(i, companies, numberRounds));
    }

    return customers;
}

export function createBlankCustomer(
    index: number,
    companies: Company[],
    numberRounds: number
): Customer {
    return {
        name: `Customer ${index + 1}`,
        description: "",
        defaultPerceivedValue: 100,
        priceSensitivity: 0.5,
        switchHurdle: 1,
        outsourcePenalty: -1,
        baseVolumeRequirement: 1000,
        volumeGrowthRate: 1,
        incumbent: "",
        rounds: generateCustomerPerRound(companies, numberRounds)
    };
}

export function generateCustomerPerRound(
    companies: Company[],
    numberRounds: number
): CustomerPerRound[] {
    const customerPerRound: CustomerPerRound[] = [];

    for (let i = 0; i < numberRounds; ++i) {
        customerPerRound.push({
            round: i + 1,
            volumeRequirement: 1000,
            perceivedValues: getPerceivedValue(companies)
        });
    }

    return customerPerRound;
}

function getPerceivedValue(companies: Company[]) {
    const perceivedValue: PerceivedValueForCompany[] = [];

    for (const company of companies) {
        perceivedValue.push({
            companyName: company.name,
            perceivedValue: 100
        });
    }

    return perceivedValue;
}

export function getBlankPerceivedValueForCompanyList(
    companies: Company[]
): PerceivedValueForCompany[] {
    const perceivedValues: PerceivedValueForCompany[] = [];

    for (const company of companies) {
        perceivedValues.push({
            companyName: company.name,
            perceivedValue: 100
        });
    }

    return perceivedValues;
}

export function validateCustomer(
    customer: Customer,
    showErrorMessages: boolean,
    customers: Customer[]
): FormErrors {
    let valid = true;
    const messages: string[] = [];

    const customerNames = customers.map((cust: Customer) => cust.name);

    for (let i = 0; i < customerNames.length; ++i) {
        const customerName = customerNames[i];

        if (customerName === customers[i].name) continue;

        if (customerName === customer.name) {
            if (showErrorMessages) {
                messages.push("Customer name is not unique");
            }
            valid = false;
        }
    }

    if (customer.name.length < 3) {
        if (showErrorMessages) {
            messages.push("Customer name must be more than 3 characters long!");
        }
        valid = false;
    }

    if (!customer.description) {
        if (showErrorMessages) {
            messages.push("Customer description is required!");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.defaultPerceivedValue < 80) {
        if (showErrorMessages) {
            messages.push("Perceived value cannot be less than 80");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.defaultPerceivedValue > 120) {
        if (showErrorMessages) {
            messages.push("Perceived value cannot be more than 120");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.priceSensitivity < 0) {
        if (showErrorMessages) {
            messages.push("Price sensitivity cannot be less than 0");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.priceSensitivity > 1) {
        if (showErrorMessages) {
            messages.push("Price sensitivity cannot be more than 1");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.switchHurdle < 0) {
        if (showErrorMessages) {
            messages.push("Switch hurdle cannot be less than 0");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.switchHurdle > 5) {
        if (showErrorMessages) {
            messages.push("Switch hurdle cannot be more than 5");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.outsourcePenalty < -10) {
        if (showErrorMessages) {
            messages.push("Outsource penalty cannot be less than -10");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.outsourcePenalty > 0) {
        if (showErrorMessages) {
            messages.push("Outsource penalty cannot be more than 0");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.baseVolumeRequirement < 0) {
        if (showErrorMessages) {
            messages.push("Base volume requirement cannot be less than 1");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.baseVolumeRequirement > 1000000) {
        if (showErrorMessages) {
            messages.push("Base volume requirement cannot be more than 1,000,000");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.volumeGrowthRate < 0) {
        if (showErrorMessages) {
            messages.push("Volume growth rate cannot be less than 1");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (customer.volumeGrowthRate > 100) {
        if (showErrorMessages) {
            messages.push("Volume growth rate cannot be more than 100");
        }
        valid = false;
    }

    if (customer.incumbent.length < 3) {
        if (showErrorMessages) {
            messages.push("Incumbent name must be more than 3 characters long!");
        }
        valid = false;
    }

    for (const perRoundSetting of customer.rounds) {
        if (perRoundSetting.round <= 0) {
            if (showErrorMessages) {
                messages.push("Round cannot be less than or equal to 0");
            }
            valid = false;
        }

        if (perRoundSetting.round > 6) {
            if (showErrorMessages) {
                messages.push("Round cannot be more than 6");
            }
            valid = false;
        }

        if (perRoundSetting.volumeRequirement === 0) {
            if (showErrorMessages) {
                messages.push("Volume requirement must be more than 0");
            }
            valid = false;
        }

        if (perRoundSetting.volumeRequirement > 1000000) {
            if (showErrorMessages) {
                messages.push("Volume requirement must be more than 1,000,000");
            }
            valid = false;
        }

        for (const company of perRoundSetting.perceivedValues) {
            if (company.perceivedValue < 80) {
                if (showErrorMessages) {
                    messages.push("Perceived value cannot be less than 80");
                }
                valid = false;
            }

            if (company.perceivedValue > 120) {
                if (showErrorMessages) {
                    messages.push("Perceived value cannot be more than 120");
                }
                valid = false;
            }
        }
    }

    return {
        valid,
        messages: showErrorMessages ? getUniqueMessages(messages) : []
    };
}

export function validateCompany(
    company: Company,
    showErrorMessages: boolean,
    companies: Company[]
): FormErrors {
    let valid = true;
    const messages: string[] = [];

    const companyNames = companies.map((comp: Company) => comp.name);

    for (let i = 0; i < companyNames.length; ++i) {
        const companyName = companyNames[i];

        if (companyName === companies[i].name) continue;

        if (companyName === company.name) {
            if (showErrorMessages) {
                messages.push("Company name is not unique");
            }
            valid = false;
        }
    }

    if (company.name.length < 3) {
        if (showErrorMessages) {
            messages.push("Company name must be more than 3 characters long!");
        }
        valid = false;
    }

    if (!company.description) {
        if (showErrorMessages) {
            messages.push("Company description is required!");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (company.sourcingLimitRate < 0) {
        if (showErrorMessages) {
            messages.push("Sourcing limit cannot be less than 0");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (company.sourcingLimitRate > 100) {
        if (showErrorMessages) {
            messages.push("Sourcing limit cannot be more than 100");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (company.baseCostPerPack < 0) {
        if (showErrorMessages) {
            messages.push("Base cost per pack cannot be less than 0");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (company.baseCostPerPack > 100) {
        if (showErrorMessages) {
            messages.push("Base cost per pack cannot be more than 100");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (company.costPerPackRate < 0) {
        if (showErrorMessages) {
            messages.push("Cost per pack rate cannot be less than 0");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (company.costPerPackRate > 100) {
        if (showErrorMessages) {
            messages.push("Cost per pack rate cannot be more than 100");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (company.baseClinicalUnitsPerPack < 0) {
        if (showErrorMessages) {
            messages.push("Base clinical units per pack cannot be less than 0.01");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (company.baseClinicalUnitsPerPack > 100) {
        if (showErrorMessages) {
            messages.push("Base clinical units per pack cannot be more than 1000");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (company.startingRound < 0) {
        if (showErrorMessages) {
            messages.push("Round must be more than 0");
        }
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (company.startingRound > 6) {
        if (showErrorMessages) {
            messages.push("Round must be more less than 6");
        }
        valid = false;
    }

    for (const perRound of company.rounds) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (perRound.round < 0) {
            if (showErrorMessages) {
                messages.push("Round must be more than 0");
            }
            valid = false;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (perRound.round > 6) {
            if (showErrorMessages) {
                messages.push("Round must be more less than 6");
            }
            valid = false;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (perRound.sourcingLimit < 0) {
            if (showErrorMessages) {
                messages.push("Sourcing limit must be more than 0");
            }
            valid = false;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (perRound.sourcingLimit > 10000) {
            if (showErrorMessages) {
                messages.push("Sourcing limit must be more than 10000");
            }
            valid = false;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (perRound.costPerPack < 0) {
            if (showErrorMessages) {
                messages.push("Sourcing limit must be more than 0");
            }
            valid = false;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (perRound.costPerPack > 100) {
            if (showErrorMessages) {
                messages.push("Sourcing limit must be more than 100");
            }
            valid = false;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (perRound.clinicalUnitsPerPack < 0) {
            if (showErrorMessages) {
                messages.push("Sourcing limit must be more than 0");
            }
            valid = false;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (perRound.clinicalUnitsPerPack > 1000) {
            if (showErrorMessages) {
                messages.push("Sourcing limit must be more than 1000");
            }
            valid = false;
        }
    }

    return {
        valid,
        messages: showErrorMessages ? getUniqueMessages(messages) : []
    };
}

export function validateGeneralSettings(game: Game): FormErrors {
    let valid = true;
    const messages: string[] = [];

    if (game.name.length < 3) {
        messages.push("Game name must be more than 3 characters long!");
        valid = false;
    }

    if (game.name.length > 256) {
        messages.push("Game name cannot be more than 256 characters");
        valid = false;
    }

    if (!game.description) {
        messages.push("Game description is required!");
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (game.description?.length > 512) {
        messages.push("Game description cannot exceed 512 characters!");
        valid = false;
    }

    if (game.numberRounds === 0) {
        messages.push("Game rounds must be more than 0");
        valid = false;
    }
    if (game.numberRounds > 6) {
        messages.push("Game rounds cannot exceed more than 6");
        valid = false;
    }

    if (game.timePerRoundMins === 0) {
        messages.push("Duration of a round must be more than 0 minutes");
        valid = false;
    }

    if (game.timePerRoundMins > 60) {
        messages.push("Duration of a round cannot be more than 60 minutes");
        valid = false;
    }

    if (game.clinicalUnitName.length < 3) {
        messages.push("Clinical unit name must be more than 3 characters long");
        valid = false;
    }

    return {
        valid,
        messages: messages.length > 0 ? getUniqueMessages(messages) : []
    };
}

export function validateGameSettings(game: Game): boolean {
    const gameErrors: string[] = [];
    const customerError: string[] = [];
    const companyError: string[] = [];

    for (const customer of game.customers) {
        customerError.push(...validateCustomer(customer, true, game.customers).messages);
    }
    for (const company of game.companies) {
        companyError.push(...validateCompany(company, true, game.companies).messages);
    }

    if (companyError.length > 0) {
        gameErrors.push("One or more company forms are not valid");
    }

    if (customerError.length > 0) {
        gameErrors.push("One or more customer forms are not valid");
    }

    gameErrors.push(...validateGeneralSettings(game).messages);

    const uniqueMessages = getUniqueMessages(gameErrors);
    if (gameErrors.length > 0) {
        for (const error of uniqueMessages) {
            showErrorToast(error);
        }
    }

    return uniqueMessages.length === 0;
}

function getUniqueMessages(messages: string[]): string[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return [...new Set(messages)];
}
