import * as React from "react";

/** Shows a spinning wheel */
const LoadingWheel = () => {
    return (
        <div className="mt-5 text-center">
            <div className="spinner-border text-dark loading-spinner" role="status" />
        </div>
    );
};

export default LoadingWheel;
