import React from "react";
import {RoundTableBase} from "./Helpers/roundTableHelpers";

const RoundTableHeader = (props: RoundTableBase) => {
    return (
        <React.Fragment>
            <thead>
                <tr className="vi-table-header">
                    {props.showRankColumn && (
                        <th align="left" className="vi-table-header-item p-3">
                            Rank
                        </th>
                    )}
                    {props.showCompanyColumn && (
                        <th align="left" className="vi-table-header-item p-3">
                            Company Name
                        </th>
                    )}
                    {props.showCustomerColumn && (
                        <th align="left" className="vi-table-header-item p-3">
                            Customer Name
                        </th>
                    )}
                    <th align="left" className="vi-table-header-item p-3">
                        Packs Delivered
                    </th>
                    <th align="left" className="vi-table-header-item p-3">
                        Bid
                    </th>
                    {!props.showRankColumn && (
                        <th align="left" className="vi-table-header-item p-3">
                            Success
                        </th>
                    )}
                    {props.showPenaltyColumn && (
                        <th align="left" className="vi-table-header-item p-3">
                            Penalty
                        </th>
                    )}
                    <th align="left" className="vi-table-header-item p-3">
                        Costs
                    </th>
                    <th align="left" className="vi-table-header-item p-3">
                        Revenue
                    </th>
                    <th align="left" className="vi-table-header-item p-3">
                        Profit
                    </th>
                    {props.showMaxRevenueColumn && (
                        <th align="left" className="vi-table-header-item p-3">
                            Max Potential Revenue
                        </th>
                    )}
                    {props.showMaxProfitColumn && (
                        <th align="left" className="vi-table-header-item p-3">
                            Max Potential Profit
                        </th>
                    )}
                </tr>
            </thead>
        </React.Fragment>
    );
};

export default RoundTableHeader;
