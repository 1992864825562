import React from "react";
import {CustomerPerRound, GameStatusCustomer, RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import ClinicalUnitRequirementTableHeader from "../../../../Shared/Components/CustomerNextRoundInfo/Table/ClinicalUnitRequirementTableHeader";
import ClinicalUnitRequirementTableRow, {
    ClinicalUnitRequirementTableRowProps
} from "../../../../Shared/Components/CustomerNextRoundInfo/Table/ClinicalUnitRequirementTableRow";

const StartingRoundCustomerInformation = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Starting Round Volume Requirement For Each Customer. (Round ${props.companies[0].startingRound})`}
                headerType={"h5"}
                showRow
            />
            <div className="pb-5 ml-3 pt-2 mr-3">
                <div className="d-block table-overflow-x vi-table-wrapper">
                    <table className="vi-table fadeIn">
                        <ClinicalUnitRequirementTableHeader {...props.customers} />
                        <tbody>
                            {getDataForClinicalUnitRequirementTableRow(props).map((item, index) => {
                                return <ClinicalUnitRequirementTableRow {...item} key={index} />;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default StartingRoundCustomerInformation;

function getDataForClinicalUnitRequirementTableRow(
    gameStatus: RunningGameStatus
): ClinicalUnitRequirementTableRowProps[] {
    const singleRow: ClinicalUnitRequirementTableRowProps = {
        roundNumber: gameStatus.companies[0].startingRound,
        clinicalUnitRequirements: []
    };

    for (const customer of gameStatus.customers) {
        const round = getCompanyStartingRound(gameStatus, customer);

        if (!round) {
            singleRow.clinicalUnitRequirements.push(1000);
            continue;
        }

        singleRow.clinicalUnitRequirements.push(round.volumeRequirement);
    }

    return [singleRow];
}

function getCompanyStartingRound(
    gameStatus: RunningGameStatus,
    customer: GameStatusCustomer
): CustomerPerRound | undefined {
    const index = customer.rounds.findIndex(
        (item) => item.round === gameStatus.companies[0].startingRound
    );

    if (index < 0) return;

    return customer.rounds[index];
}
