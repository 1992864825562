import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {createNewGameMaster} from "../../../store/gameMaster/actions/GameMasterActions";
import EditGameMasterForm from "./Components/EditGameMasterForm";
import WithServiceState from "../../../modules/storeFetchWrappers/components/WithServiceState";
import {RootStore} from "../../../store/Store";
const ServiceWrapper = WithServiceState(EditGameMasterForm);

const AddGameMaster = () => {
    const dispatch = useDispatch();

    const gameMasterStore = useSelector((state: RootStore) => state.gameMaster);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.gameMasterList.name,
                path: routeNames.gameMasterList.path
            },
            {
                name: routeNames.gameList.name,
                path: routeNames.gameList.path
            }
        ];

        dispatch(setNavigationItems(navItems));
        dispatch(createNewGameMaster());
    }, []);

    return (
        <React.Fragment>
            <div className="container fadeIn">
                <ServiceWrapper showErrorMessage={false} {...gameMasterStore} />
            </div>
        </React.Fragment>
    );
};

export default AddGameMaster;
