import {Dispatch} from "redux";
import {GAME_SETTINGS_STORE, GameSettingsDispatchTypes} from "./GameSettingsActionTypes";
import {
    createBlankCompany,
    createBlankCompanyPerRound,
    createBlankCustomer,
    createBlankGamePerRound,
    generateCustomerPerRound,
    generateNewSettings,
    getBlankPerceivedValueForCompanyList,
    validateGameSettings
} from "../helpers/gameSettingsHelpers";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithData,
    postDataToServiceWithData
} from "../../../modules/storeFetchWrappers/store/helpers/utils/StoreFetchWrappers";
import TenderGameApiModel from "../../apiModel/TenderGameApiModel";
import {
    Company,
    CompanyPerRound,
    CopyGameRequest,
    Customer,
    CustomerPerRound,
    Game,
    GamePerRound,
    PerceivedValueForCompany
} from "../../../api";
import {RootStore} from "../../Store";
import {showErrorToast} from "../../../utils/toastUtils";
import {getAdminHeader, statusCodeCallback} from "../../apiModel/BaseApi";
import {capitalizeFirstLetter, decapitalizeFirstLetter} from "../../../utils/textUtils";
import moment from "moment";

export const nullifyGameSettingsStore = () => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const createNewBlankGameSettings = (
    numberCompanies: number,
    numberRounds: number,
    numberCustomers: number
) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: generateNewSettings(numberCompanies, numberRounds, numberCustomers)
        });
    };
};

export const updateNumberOfRounds = (game: Game) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        const updatedCompaniesGame = updateNumberOfCompanyPerRoundSettings(game);
        updatedCompaniesGame.roundConfigs = createBlankGamePerRound(
            game.numberRounds,
            game.timePerRoundMins
        );
        const updatedGame = updateNumberOfCustomerPerRoundSettings(game);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: updatedGame
        });
    };
};

export const updateGamePerRoundSetting = (gamePerRound: GamePerRound) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const game = state().gameSettings.data;
        if (!game) return;

        const index = game.roundConfigs.findIndex((item) => item.round === gamePerRound.round);

        if (index < 0) return;

        game.roundConfigs[index] = gamePerRound;
        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: game
        });
    };
};

export const updatePerceivedValueForCompany = (
    customer: Customer,
    value: PerceivedValueForCompany
) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        // const updatedGame = updateNumberOfCompanyPerRoundSettings(game);
        const game = deepCopy(state().gameSettings.data);
        if (!game) return;

        const customerIndex = game.customers.findIndex((cust) => cust.name === customer.name);
        if (customerIndex < 0) return;

        for (const round of game.customers[customerIndex].rounds) {
            const companyPerceivedValueIndex = round.perceivedValues.findIndex(
                (item) => item.companyName === value.companyName
            );
            if (companyPerceivedValueIndex < 0) continue;

            round.perceivedValues[companyPerceivedValueIndex] = value;
        }
        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: game
        });
    };
};

function updateNumberOfCompanyPerRoundSettings(game: Game) {
    const rounds = createBlankCompanyPerRound(game.numberRounds);
    for (const company of game.companies) {
        company.rounds = rounds;
    }

    return game;
}
function updateNumberOfCustomerPerRoundSettings(game: Game) {
    const rounds = generateCustomerPerRound(game.companies, game.numberRounds);
    for (const customer of game.customers) {
        customer.rounds = rounds;
    }

    return game;
}

export const setLocalSettings = (game: Game) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: processGameIncoming(game)
        });
    };
};

export const addCustomerSettings = () => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        if (gameSettings.customers.length === 6) {
            showErrorToast("Cannot have more than 6 customers");
            return;
        }

        gameSettings.customers.push(
            createBlankCustomer(
                gameSettings.customers.length,
                gameSettings.companies,
                gameSettings.numberRounds
            )
        );

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setCustomerSettings = (args: SetCustomerArgs) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        gameSettings.customers[args.index] = args.customer;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setCustomerIncumbentSettings = (args: SetCustomerArgs, incumbent: string) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        gameSettings.customers[args.index].incumbent = incumbent;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const deleteCustomerSettings = (index: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        if (gameSettings.customers.length === 6) {
            showErrorToast("Cannot have less than 6 customers");
            return;
        }

        gameSettings.customers.splice(index, 1);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const addCompanySettings = () => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        if (gameSettings.companies.length === 4) {
            showErrorToast("Cannot have more than 4 companies");
            return;
        }

        gameSettings.companies.push(
            createBlankCompany(gameSettings.companies.length, gameSettings.numberRounds)
        );

        for (const customer of gameSettings.customers) {
            for (const round of customer.rounds) {
                const companyName = `Company ${gameSettings.companies.length}`;
                round.perceivedValues.push({companyName, perceivedValue: 100});
            }
        }

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setCompanyName = (args: SetCompanyArgs) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;
        const oldName = gameSettings.companies[args.index].name;

        gameSettings.companies[args.index] = args.company;

        for (const customer of gameSettings.customers) {
            for (const round of customer.rounds) {
                const index = round.perceivedValues.findIndex(
                    (item) => item.companyName === oldName
                );
                if (index < 0) continue;

                round.perceivedValues[index].companyName = args.company.name;
            }
        }

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setCompanySettings = (args: SetCompanyArgs) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        gameSettings.companies[args.index] = args.company;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setCompanyCostPerGoodsSettings = (args: SetCompanyArgs) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        gameSettings.companies[args.index] = args.company;

        for (const roundSetting of gameSettings.companies[args.index].rounds) {
            roundSetting.costPerPack = args.company.costPerPackRate || 10;
        }

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const deleteCompanySettings = (index: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        if (index < 0) return;

        if (gameSettings.companies.length === 2) {
            showErrorToast("Cannot have less than 2 companies");
            return;
        }

        for (const customer of gameSettings.customers) {
            for (const round of customer.rounds) {
                const perceivedIndex = round.perceivedValues.findIndex(
                    (item) => item.companyName === gameSettings.companies[index].name
                );
                if (index < 0) continue;

                round.perceivedValues.splice(perceivedIndex, 1);
            }
        }

        gameSettings.companies.splice(index, 1);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const addPerRoundCustomerSettings = (customerIndex: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;
        if (gameSettings.customers[customerIndex].rounds.length === 6) {
            showErrorToast(
                "Cannot have more per round settings than the max number of rounds (6)."
            );
            return;
        }
        const round: CustomerPerRound = {
            round: 0,
            volumeRequirement: 1000,
            perceivedValues: getBlankPerceivedValueForCompanyList(gameSettings.companies)
        };
        gameSettings.customers[customerIndex].rounds.push(round);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setPerRoundCustomerSettings = (args: SetCustomerPerRoundArgs) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = deepCopy(state().gameSettings.data);
        if (!gameSettings) return;

        const customerIndex = gameSettings.customers.findIndex(
            (customer) => customer.name === args.customerName
        );

        gameSettings.customers[customerIndex].rounds[args.roundSettingIndex] =
            args.customerPerRound;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setPerRoundCustomerPerceivedValueSettings = (
    args: SetPerceivedValueForCompanyArgs
) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        gameSettings.customers[args.customerIndex].rounds[
            args.customerPerRoundIndex
        ].perceivedValues[args.perceivedValueIndex] = args.perceivedValue;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const removePerRoundCustomerSettings = (
    customerIndex: number,
    customerPerRoundIndex: number
) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;
        gameSettings.customers[customerIndex].rounds.splice(customerPerRoundIndex, 1);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const addPerRoundCompanySettings = (companyIndex: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;
        if (gameSettings.companies[companyIndex].rounds.length === 6) {
            showErrorToast(
                "Cannot have more per round settings than the max number of rounds (6)."
            );
            return;
        }
        gameSettings.companies[companyIndex].rounds.push({
            round: 1,
            sourcingLimit: 1,
            costPerPack: 1,
            clinicalUnitsPerPack: 1000
        });

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setPerRoundCompanySettings = (args: SetCompanyPerRoundArgs) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = deepCopy(state().gameSettings.data);
        if (!gameSettings) return;

        const companyIndex = gameSettings.companies.findIndex(
            (company) => company.name === args.companyName
        );

        if (companyIndex < 0) return;

        gameSettings.companies[companyIndex].rounds[args.roundSettingIndex] = args.companyPerRound;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const removePerRoundCompanySettings = (
    companyIndex: number,
    companyPerRoundIndex: number
) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;
        gameSettings.companies[companyIndex].rounds.splice(companyPerRoundIndex, 1);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const createCopyOfGame = (request: CopyGameRequest) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        try {
            return await postDataToServiceWithData(
                GAME_SETTINGS_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().copyGame(request, getAdminHeader()),
                statusCodeCallback
            );
        } catch (error: any) {
            dispatch({
                type: GAME_SETTINGS_STORE.ERROR,
                loading: false,
                error: error.response.data.message
            });
        }
    };
};

export const fetchGameById = (id: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        try {
            const data = await getDataFromServiceWithData(
                GAME_SETTINGS_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().getGame(id, getAdminHeader()),
                statusCodeCallback
            );
            if (!data) return;

            const fixedData: Game = processGameIncoming(data);

            dispatch({
                type: GAME_SETTINGS_STORE.SUCCESS,
                loading: false,
                error: null,
                data: fixedData
            });
        } catch (e: any) {
            dispatch({
                type: GAME_SETTINGS_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const deleteGameById = (id: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                GAME_SETTINGS_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().deleteGame(id, getAdminHeader()),
                statusCodeCallback
            );
        } catch (error: any) {
            dispatch({
                type: GAME_SETTINGS_STORE.ERROR,
                loading: false,
                error: error
            });
            return false;
        }
    };
};

export const saveGameToService = (game: Game) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        try {
            const isValid = validateGameSettings(game);

            if (!isValid) return;

            const savedGame = await postDataToServiceWithData(
                GAME_SETTINGS_STORE,
                dispatch,
                () =>
                    TenderGameApiModel.getTenderApi().saveGame(
                        processGameOutgoing(game),
                        getAdminHeader()
                    ),
                statusCodeCallback
            );

            if (!savedGame) return null;
            const updatedFixedData: Game = processGameIncoming(savedGame);

            dispatch({
                type: GAME_SETTINGS_STORE.SUCCESS,
                loading: false,
                error: null,
                data: updatedFixedData
            });

            return updatedFixedData;
        } catch (e: any) {
            dispatch({
                type: GAME_SETTINGS_STORE.ERROR,
                loading: false,
                error: e
            });
            return null;
        }
    };
};

interface SetCustomerArgs {
    index: number;
    customer: Customer;
}

interface SetCustomerPerRoundArgs {
    roundSettingIndex: number;
    customerPerRound: CustomerPerRound;
    customerName: string;
}

interface SetCompanyPerRoundArgs {
    roundSettingIndex: number;
    companyPerRound: CompanyPerRound;
    companyName: string;
}

interface SetCompanyArgs {
    index: number;
    company: Company;
}

interface SetPerceivedValueForCompanyArgs {
    customerIndex: number;
    customerPerRoundIndex: number;
    perceivedValueIndex: number;
    perceivedValue: PerceivedValueForCompany;
}

function processGameIncoming(game: Game): Game {
    const maxDescriptionLength = 512;
    return {
        ...game,
        description: (game.description || "").substring(0, maxDescriptionLength),
        companies: game.companies.map((company) => {
            return {
                ...company,
                description: (company.description || "").substring(0, maxDescriptionLength)
            };
        }),
        customers: game.customers.map((customer) => {
            return {
                ...customer,
                description: (customer.description || "").substring(0, maxDescriptionLength)
            };
        }),
        state: {
            ...game.state,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            step: capitalizeFirstLetter(game.state.step)
        }
    };
}

function processGameOutgoing(game: Game): Game {
    const maxDescriptionLength = 512;
    return {
        ...game,
        description: (game.description || "").substring(0, maxDescriptionLength),
        dateCreated: game.id > 0 ? game.dateCreated : moment().startOf("day").unix(),
        companies: game.companies.map((company) => {
            return {
                ...company,
                description: (company.description || "").substring(0, maxDescriptionLength)
            };
        }),
        customers: game.customers.map((customer) => {
            return {
                ...customer,
                description: (customer.description || "").substring(0, maxDescriptionLength)
            };
        }),
        state: {
            ...game.state,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            step: decapitalizeFirstLetter(game.state.step)
        }
    };
}

export function deepCopy<T>(data: T): T {
    return JSON.parse(JSON.stringify(data));
}
