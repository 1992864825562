import React from "react";

const CompleteRoundTableRankingHeader = (props: CompleteRoundTableHeaderProps) => {
    return (
        <React.Fragment>
            <tr className="vi-table-header">
                <th align="left" className="vi-table-header-item p-3">
                    Rank
                </th>
                <th align="left" className="vi-table-header-item p-3">
                    Company Name
                </th>
                <th align="left" className="vi-table-header-item p-3">
                    {props.extraColumnName}
                </th>
            </tr>
        </React.Fragment>
    );
};

export default CompleteRoundTableRankingHeader;

interface CompleteRoundTableHeaderProps {
    showExtraColumn?: boolean;
    extraColumnName?: string;
}
