import React from "react";
import {RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import MarketShareBarChart from "./MarketShareBarChart";
import AveragePriceLineChart from "./AveragePriceLineChart";
import ValueLostAreaChart from "./ValueLostAreaChart";

const PostGameGraphs = (props: RunningGameStatus) => (
    <React.Fragment>
        <FormHeader rowClassName={"mt-3"} headerName={"Statistics"} headerType={"h2"} showRow />
        <MarketShareBarChart status={props} marketShareType={"Value"} />
        <MarketShareBarChart status={props} marketShareType={"Volume"} />
        <AveragePriceLineChart {...props} />
        <ValueLostAreaChart {...props} />
    </React.Fragment>
);

export default PostGameGraphs;
