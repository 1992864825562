import React from "react";
import logo from "../../.././../images/VI_LogoStrapline_Blue-Gold_Primary.png";
import {useHistory} from "react-router-dom";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../Button/VIButton";

const FourOhFour = () => {
    const history = useHistory();
    const onBackPressed = () => {
        history.goBack();
    };
    return (
        <React.Fragment>
            <div className="container fadeIn">
                <div className="pt-5" />
                <div className="d-grid min-vh-100">
                    <div className="row">
                        <div className="col-6">
                            <img src={logo} className="max-width-400px" alt="" />
                        </div>
                        <div className="col-6 text-center mt-5">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            <h3>This isn't insight-ful</h3>
                            <h6 className="mt-3">It is just a 404 Error!</h6>
                            <h6>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                What you're looking for may have been misplaced in somewhere in the
                                world.
                            </h6>
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Go Back"}
                                onClick={onBackPressed}
                                colour={ButtonColourOptions.Orange}
                                className={"max-width-200px"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default FourOhFour;
