import React, {useMemo} from "react";
import {RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import {getRoundStatsForMarketShare, MarketShareType, RoundStats} from "../DataTables/DataTables";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import {getActualPercent} from "../../../../../../../utils/mathUtils";
import {Bar} from "react-chartjs-2";
import {getRoundLabels} from "./ValueLostAreaChart";
import {defaultColours} from "../../../Components/InRound/PreviousRoundViews/PreviousRoundStats";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "bottom" as const
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart"
        }
    },
    scales: {
        y: {
            min: 0,
            max: 100
        }
    }
};

const MarketShareBarChart = (props: MarketShareBarChartProps) => {
    const data = useMemo(() => getMarketShareData(props), []);
    return (
        <React.Fragment>
            <FormHeader
                headerName={`Market Share (%) - ${props.marketShareType}`}
                headerType={"h4"}
                showRow
                rowClassName="mt-3"
            />
            <Bar options={options} data={data} />
        </React.Fragment>
    );
};

export default MarketShareBarChart;

function getMarketShareData(props: MarketShareBarChartProps): MarketShareGraphProps {
    getDatasetForCompany(props);

    return {
        labels: getRoundLabels(props.status.numberRounds),
        datasets: getDatasetForCompany(props)
    };
}

function getDatasetForCompany(props: MarketShareBarChartProps): MarketShareProps[] {
    return getMarketShareForCompanies(props).map((item, index) => {
        return {
            label: item.label,
            data: item.data.map((d) => d.share),
            backgroundColor: defaultColours[index]
        };
    });
}

interface MarketShareGraphProps {
    labels: string[];
    datasets: MarketShareProps[];
}

interface MarketShareProps {
    label: string;
    data: number[];
    backgroundColor: string;
}

export type MarketShareDatum = {round: string; share: number};

export interface MarketShareBarChartProps {
    marketShareType: MarketShareType;
    status: RunningGameStatus;
}

export function getMarketShareForBarCharts(
    props: MarketShareBarChartProps
): ChartProps<MarketShareDatum>[] {
    return getMarketShareForCompanies(props);
}

export interface ChartProps<T> {
    label: string;
    data: T[];
}

function getMarketShareForCompanies(
    props: MarketShareBarChartProps
): ChartProps<MarketShareDatum>[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const allRoundStats: RoundStats[] = sortAlphabetically(getAllRoundStats(props), "companyName");
    const companyNames = props.status.companies.map((item) => item.name);

    return sortRoundStatsToChartProps(allRoundStats, companyNames);
}

function sortRoundStatsToChartProps(roundStats: RoundStats[], companyNames: string[]) {
    const chartProps: ChartProps<MarketShareDatum>[] = [];

    for (const name of companyNames) {
        const stats = roundStats.filter((item) => item.companyName === name);
        chartProps.push(sortFilteredRoundStatsToChartProps(stats, name));
    }

    return chartProps;
}

function sortFilteredRoundStatsToChartProps(
    roundStats: RoundStats[],
    companyName: string
): ChartProps<MarketShareDatum> {
    return {
        label: companyName,
        data: roundStats.map((item) => {
            return {
                round: `Round ${item.roundNumber}`,
                share: getActualPercent(item.value)
            };
        })
    };
}

function getAllRoundStats(props: MarketShareBarChartProps) {
    const allRoundStats: RoundStats[] = [];
    for (const company of props.status.companies) {
        allRoundStats.push(
            ...getRoundStatsForMarketShare(props.status, props.marketShareType, company)
        );
    }
    return allRoundStats;
}

function sortAlphabetically<T extends {[key: string]: string}>(items: T[], key: string): T[] {
    return items.sort((a, b) => {
        if (!a[key]) return 0;
        if (!b[key]) return 0;

        return a[key].localeCompare(b[key]);
    });
}
