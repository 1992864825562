import {Dispatch} from "redux";
import {GAME_MASTER_LIST_STORE, GameMasterListDispatchTypes} from "./GameMasterListActionTypes";
import {getDataFromServiceWithRedux} from "../../../modules/storeFetchWrappers/store/helpers/utils/StoreFetchWrappers";
import TenderGameApiModel from "../../apiModel/TenderGameApiModel";
import {getAdminHeader, statusCodeCallback} from "../../apiModel/BaseApi";

export const nullifyGameMasterListStore = () => {
    return async (dispatch: Dispatch<GameMasterListDispatchTypes>) => {
        dispatch({
            type: GAME_MASTER_LIST_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const getGameMasterList = () => {
    return async (dispatch: Dispatch<GameMasterListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                GAME_MASTER_LIST_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().listGamemasters(getAdminHeader()),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GAME_MASTER_LIST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
