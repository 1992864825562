import React, {useEffect} from "react";
import {Gamemaster} from "../../../../api";
import {toArray} from "../../../../utils/ArrayUtils";
import GameMasterTableHeader from "./GameMasterTableHeader";
import GameMasterTableRow from "./GameMasterTableRow";
import {useDispatch} from "react-redux";
import {nullifyGameMasterListStore} from "../../../../store/gameMasterList/actions/GameMasterListActions";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../Button/VIButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";

const GameMasterTable = (props: Gamemaster[]) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        return () => {
            dispatch(nullifyGameMasterListStore());
        };
    }, []);

    const addGameMaster = () => {
        const pathname = routeNames.gameMasterAdd.path;
        history.push(pathname);
    };

    return (
        <React.Fragment>
            {toArray(props).length > 0 ? (
                <React.Fragment>
                    <div className="pt-5 pb-5">
                        <div className="row ml-0 mr-0 mb-2">
                            <div className="col pr-0 d-flex justify-content-end">
                                <VIButton
                                    size={ButtonSize.Large}
                                    innerValue={"Add Game Master"}
                                    onClick={addGameMaster}
                                    colour={ButtonColourOptions.LightBlue}
                                    roundedCorner
                                />
                            </div>
                        </div>
                        <div className="d-block table-overflow-x vi-table-wrapper">
                            <table className="vi-table fadeIn">
                                <GameMasterTableHeader />
                                <tbody>
                                    {toArray(props).map((item: Gamemaster, index: number) => {
                                        return <GameMasterTableRow {...item} key={index} />;
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            There are no Game masters in the system.
                        </h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default GameMasterTable;
