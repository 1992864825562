export const routeNames = {
    gameSettingsNew: {
        path: "/game/settings/new",
        name: "New Game Settings"
    },
    gameSettingsEdit: {
        path: "/game/settings/edit",
        name: "Edit Game Settings"
    },
    gameSettingsCopy: {
        path: "/game/settings/copy",
        name: "Copy Game Settings"
    },
    userLogin: {
        path: "/login",
        name: "Login"
    },
    gameMasterLogin: {
        path: "/login/game-master",
        name: "Game Master Login"
    },
    gameList: {
        path: "/game-master/game/list",
        name: "Game List"
    },
    gameMasterList: {
        path: "/game-master/list",
        name: "Game Master List"
    },
    gameMasterAdd: {
        path: "/game-master/add",
        name: "Add Game Master"
    },
    currentGame: {
        path: "/game/current",
        name: "Game"
    },
    currentGameGameMaster: {
        path: "/game-master/game/current",
        name: "Game"
    },
    completeGameGameMaster: {
        path: "/game-master/game/complete",
        name: "Game"
    },
    fourOhOne: {
        path: "/401",
        name: "Not Authenticated"
    },
    fourOhThree: {
        path: "/403",
        name: "Not Authorized"
    },
    fourOhFour: {
        path: "/404",
        name: "Not Found"
    }
};
