import React from "react";
import {
    CompanyAdditionalRoundStats,
    GameStatusCompany,
    Round,
    RunningGameStatus
} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import CompleteRoundTable from "../Table/CompleteRoundTable";
import {bankersRound, getActualPercent} from "../../../../../../../utils/mathUtils";
import CompleteRoundTableRowWrapper from "../Table/CompleteRoundTableRowWrapper";

const DataTables = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormHeader rowClassName={"mt-3"} headerName={"Stats"} headerType={"h2"} showRow />
            <CompleteRoundTable headerType={"Data"} headerName={"Average Price"}>
                {getAveragePriceForAllRounds(props, "Average Price")}
            </CompleteRoundTable>
            <CompleteRoundTable headerType={"Data"} headerName={"Market Share - Value"}>
                {getMarketShareForCompanies(props, "Value")}
            </CompleteRoundTable>
            <CompleteRoundTable headerType={"Data"} headerName={"Market Share - Volume"}>
                {getMarketShareForCompanies(props, "Volume")}
            </CompleteRoundTable>
            <CompleteRoundTable headerType={"Data"} headerName={"Value Lost"}>
                {getValueLost(props)}
            </CompleteRoundTable>
            <CompleteRoundTable headerType={"Data"} headerName={"Marketing Profit"}>
                {getMarketingProfit(props)}
            </CompleteRoundTable>
        </React.Fragment>
    );
};

export default DataTables;

/** AVERAGE PRICE TABLE */
function getAveragePriceForAllRounds(props: RunningGameStatus, rowName: string): JSX.Element {
    const items: JSX.Element[] = [
        <td key={0} align="left" className="vi-table-row-item-item p-3">
            {rowName}
        </td>
    ];
    for (const round of props.rounds) {
        items.push(
            <td align="left" className="vi-table-row-item-item p-3">
                {round.averageBid ? bankersRound(round.averageBid) : 100}
            </td>
        );
    }

    return <CompleteRoundTableRowWrapper>{items}</CompleteRoundTableRowWrapper>;
}

/** MARKET SHARE BY VALUE AND VOLUME */
function getMarketShareForCompanies(
    props: RunningGameStatus,
    marketShareType: MarketShareType
): JSX.Element[] {
    const items: JSX.Element[] = [];

    for (const company of props.companies) {
        const roundStats = getRoundStatsForMarketShare(props, marketShareType, company);
        items.push(generateTdsForRow(company.name, roundStats, "Percentage"));
    }

    return items;
}

export function getRoundStatsForMarketShare(
    props: RunningGameStatus,
    marketShareType: MarketShareType,
    company: GameStatusCompany
): RoundStats[] {
    const stats = props.companyRoundStats
        .filter((item) => item.companyName === company.name)
        .sort((a, b) => a.round - b.round);
    return getValueFromStats(stats, props, marketShareType, company.name);
}

function getValueFromStats(
    stats: CompanyAdditionalRoundStats[],
    props: RunningGameStatus,
    marketShareType: MarketShareType,
    companyName: string
): RoundStats[] {
    const roundStats: RoundStats[] = [];
    for (let i = 0; i < props.numberRounds; ++i) {
        const roundNumber = i + 1;

        const index = stats.findIndex((item) => item.round === roundNumber);

        if (index < 0) {
            roundStats.push({
                roundNumber,
                value: 0,
                companyName
            });
            continue;
        }

        roundStats.push({
            roundNumber,
            value: getMarketShareValueFromType(marketShareType, stats[index]),
            companyName
        });
    }

    return roundStats;
}

function getMarketShareValueFromType(
    marketShareType: MarketShareType,
    stat: CompanyAdditionalRoundStats
) {
    switch (marketShareType) {
        case "Value":
            return stat.marketShare;
        case "Volume":
            return stat.marketShareByVolume;
    }
}

export type MarketShareType = "Value" | "Volume";

export interface RoundStats {
    roundNumber: number;
    value: number;
    companyName?: string;
}

/** VALUE LOST */
function getValueLost(props: RunningGameStatus) {
    const elements: JSX.Element[] = [];
    const maximalRevenue = getMaximalRevenueProfitsForGame(props, "MaxRevenue");
    const remainingRevenue = getMaximalRevenueProfitsForGame(props, "Revenue");
    maximalRevenue.splice(0, 1);
    remainingRevenue.splice(0, 1);

    elements.push(generateTdsForRow("Maximal Value", maximalRevenue, "BankersRound"));
    elements.push(generateTdsForRow("Remaining Value", remainingRevenue, "BankersRound"));

    return elements;
}

export function getMaximalRevenueProfitsForGame(props: RunningGameStatus, type: LostValueType) {
    // 1872, 306, 525, 412, 1260, 2184 = 6559
    const startRequirement = props.customers.reduce((a, b) => a + b.rounds[0].volumeRequirement, 0);
    const roundStats: RoundStats[] = [
        {
            roundNumber: 0,
            value: startRequirement * 100
        }
    ];

    const propertyIndex = type === "MaxRevenue" ? "maxPotentialRevenue" : "revenue";

    for (const round of props.rounds) {
        roundStats.push({
            roundNumber: round.number,
            value: round.bids.reduce((a, b) => a + getValueOfItem(b[propertyIndex]), 0)
        });
    }

    return roundStats.sort((a, b) => a.roundNumber - b.roundNumber);
}

export function getValueOfItem(value?: number): number {
    if (!value) return 0;
    return value;
}

export type LostValueType = "MaxRevenue" | "Revenue";

/** MARKETING PROFIT */
function getMarketingProfit(props: RunningGameStatus) {
    const companies = getCompanyNames(props.companies);
    const rows: JSX.Element[] = [];

    for (const company of companies) {
        const sortedRow = getCompanyBidsForGame(company, props.rounds);

        rows.push(generateTdsForRow(company, sortedRow, "Normal"));
    }

    return rows;
}

export function getCompanyBidsForGame(company: string, rounds: Round[]) {
    const sortedRow: RoundStats[] = [];
    for (const round of rounds) {
        const filteredBids = round.bids.filter((item) => item.companyName === company);

        sortedRow.push({
            roundNumber: round.number,
            value: filteredBids.reduce((a, b) => a + getValueOfItem(b.profit), 0)
        });
    }
    return sortedRow;
}

export function getCompanyNames(companies: GameStatusCompany[]): string[] {
    return companies.map((item) => item.name);
}

/** TABLE STUFF */
export function generateTdsForRow(rowName: string, items: RoundStats[], type: TDType): JSX.Element {
    return (
        <CompleteRoundTableRowWrapper>
            <td align="left" className="vi-table-row-item-item p-3">
                {rowName}
            </td>
            {items.map((item) => {
                return getTdsBasedOnType(type, item);
            })}
        </CompleteRoundTableRowWrapper>
    );
}

function getTdsBasedOnType(type: TDType, roundStat: RoundStats) {
    switch (type) {
        case "BankersRound":
            return (
                <td align="left" className="vi-table-row-item-item p-3">
                    {bankersRound(roundStat.value)}
                </td>
            );
        case "Normal":
            return (
                <td align="left" className="vi-table-row-item-item p-3">
                    {roundStat.value}
                </td>
            );
        case "Percentage":
            return (
                <td align="left" className="vi-table-row-item-item p-3">
                    {getActualPercent(roundStat.value)}%
                </td>
            );
    }
}

type TDType = "Percentage" | "BankersRound" | "Normal";
