import React from "react";
import {Company, Customer, GameStep} from "../../../../../../api";
import FormRow from "../../../../../Form/FormRow";
import {useGameState} from "../../../../../Hooks/useGameState";
import {useDispatch} from "react-redux";
import {updatePerceivedValueForCompany} from "../../../../../../store/gameSettings/actions/GameSettingsActions";
import FormError from "../../../../../Form/FormError";

const PerceivedValueItem = (props: PerceivedValueItemProps) => {
    const gameState = useGameState();
    const dispatch = useDispatch();

    const getDefaultPerceivedValue = () => {
        const {customer, company} = props;

        const index = customer.rounds[0].perceivedValues.findIndex(
            (item) => item.companyName === company.name
        );

        if (index < 0) return 100;

        return customer.rounds[0].perceivedValues[index].perceivedValue;
    };

    return (
        <React.Fragment>
            <FormRow
                rowName={`${props.company.name} Perceived Value`}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={"The perceived value of this company. Higher values = more prestige"}
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={getDefaultPerceivedValue()}
                        onChange={(event) => {
                            const {value} = event.target;

                            dispatch(
                                updatePerceivedValueForCompany(props.customer, {
                                    perceivedValue: +value,
                                    companyName: props.company.name
                                })
                            );
                        }}
                        placeholder={`Enter ${props.company.name} perceived value....`}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {getPerceivedValueError(getDefaultPerceivedValue())}
                </React.Fragment>
            </FormRow>
        </React.Fragment>
    );
};

export default PerceivedValueItem;

interface PerceivedValueItemProps {
    customer: Customer;
    company: Company;
}

function getPerceivedValueError(perceivedValue?: number) {
    if (perceivedValue === 0) {
        return <FormError errorMessage={"Perceived value cannot be less than 80"} />;
    }
    if (!perceivedValue) return;
    if (perceivedValue < 80) {
        return <FormError errorMessage={"Perceived value cannot be less than 80"} />;
    }

    if (perceivedValue > 120) {
        return <FormError errorMessage={"Perceived value cannot be more than 120"} />;
    }
}
