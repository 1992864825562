import React from "react";
import {RunningGameStatus} from "../../../../../../api";
import FormWrapper from "../../../../../Form/FormWrapper";
import FormHeader from "../../../../../Form/FormHeader";
import FormRow from "../../../../../Form/FormRow";
import CustomerNextRoundInfo from "../../../Shared/Components/CustomerNextRoundInfo/CustomerNextRoundInfo";
import SharedGameInformation from "../Shared/SharedGameInformation";
import StartingRoundCompanyInformation from "./Components/StartingRoundCompanyInformation";
import StartingRoundCustomerInformation from "./Components/StartingRoundCustomerInformation";

const PendingStartScreenNotInNextRound = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormWrapper>
                <FormHeader
                    rowClassName={"mt-3"}
                    headerName={`Welcome ${props.companies[0].name}`}
                    headerType={"h2"}
                    showRow
                />
                <FormRow rowName={""} formRowClassName={"ml-3 pt-3 pr-3"}>
                    <p className="dark-blue-text">
                        The Game Master has ruled that your company will start in round{" "}
                        {props.companies[0].startingRound}. The current round is {props.round}.
                        Please wait for the game to get to the starting round for your company. Any
                        questions, please ask the Game Master!
                    </p>
                </FormRow>
                <SharedGameInformation {...props} />
                <StartingRoundCompanyInformation {...props} />
                <StartingRoundCustomerInformation {...props} />
                <CustomerNextRoundInfo gameStatus={props} showNextRoundText={false} />
                <div className="row ml-3 mr-3 pt-3">
                    <p className="dark-blue-text">
                        The game has not started yet! Whilst you wait, here are details about the
                        game!
                    </p>
                </div>
            </FormWrapper>
        </React.Fragment>
    );
};

export default PendingStartScreenNotInNextRound;
