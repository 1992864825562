import React from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../../store/Store";

const CompleteRoundTableDataHeader = (props: CompleteRoundTableHeaderProps) => {
    const game = useSelector((state: RootStore) => state.runningGameStatus.data);
    return (
        <React.Fragment>
            <tr className="vi-table-header">
                <th align="left" className="vi-table-header-item p-3" />
                {game &&
                    game.rounds.map((round, index) => (
                        <th align="left" className="vi-table-header-item p-3" key={index}>
                            {round.number}
                        </th>
                    ))}
                {props.showExtraColumn && (
                    <th align="left" className="vi-table-header-item p-3">
                        {props.extraColumnName}
                    </th>
                )}
            </tr>
        </React.Fragment>
    );
};

export default CompleteRoundTableDataHeader;

interface CompleteRoundTableHeaderProps {
    showExtraColumn?: boolean;
    extraColumnName?: string;
}
