import {
    ServiceActionTypes,
    StoreServiceData
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createDefaultStoreState, createReducer} from "../../helpers/storeHelpers";
import {USER_STORE} from "../actions/UserActionTypes";

const defaultState: StoreServiceData<string> = createDefaultStoreState<string>(null);

const userReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
): StoreServiceData<string> => createReducer<string>(state, action, USER_STORE);

export default userReducer;
