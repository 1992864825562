import React, {useCallback, useEffect, useState} from "react";
import FormWrapper from "../../Form/FormWrapper";
import FormHeader from "../../Form/FormHeader";
import FormRow from "../../Form/FormRow";
import FormActionContainer from "../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../Button/VIButton";
import {Gamemaster} from "../../../api";
import {validateGameMasterDetails} from "../../../store/gameMaster/helpers/gameMasterHelpers";
import {useHistory} from "react-router-dom";
import {getEncodedToken} from "../../../store/apiModel/BaseApi";
import {routeNames} from "../../Navigation/routeNames";

const GameMasterLogin = () => {
    const [gameMaster, setGameMaster] = useState<Gamemaster>({email: "", password: ""});
    const history = useHistory();

    const checkKeyPress = useCallback(
        (e) => {
            const {keyCode} = e;

            if (keyCode === 13) {
                enterApp();
            }
        },
        [gameMaster]
    );

    useEffect(() => {
        document.addEventListener("keyup", checkKeyPress);

        return () => document.removeEventListener("keyup", checkKeyPress);
    }, [checkKeyPress]);

    const enterApp = () => {
        console.log(gameMaster);
        const isLoginValid = validateGameMasterDetails(gameMaster);
        if (!isLoginValid) return;
        const {email, password} = gameMaster;
        const base64EncodedAuth = getEncodedToken(email, password);
        localStorage.setItem("basic_auth_creds", base64EncodedAuth);
        const redirectUri = localStorage.getItem("redirect_uri");

        if (redirectUri && redirectUri.includes(routeNames.completeGameGameMaster.path)) {
            history.push(redirectUri);
            return;
        }
        history.push(routeNames.gameList.path);
    };

    return (
        <React.Fragment>
            <div className="container min-vh-100 d-grid">
                <FormWrapper wrapperClassName={"min-width-350px fadeIn"}>
                    <FormHeader
                        headerName={"Login"}
                        textAlign={"center"}
                        headerType={"h3"}
                        showRow
                    />
                    <FormRow
                        rowName={"Email:"}
                        formRowClassName={"mt-3"}
                        rowClassName={"ml-3 mr-4 pr-2"}
                    >
                        <input
                            onChange={(event) => {
                                const {value} = event.target;
                                setGameMaster({
                                    ...gameMaster,
                                    email: value
                                });
                            }}
                            className="input-fields"
                            type={"email"}
                            disabled={false}
                            value={gameMaster.email}
                            placeholder={"Enter email..."}
                        />
                    </FormRow>
                    <FormRow
                        rowName={"Password:"}
                        formRowClassName={"mt-3"}
                        rowClassName={"ml-3 mr-4 pr-2"}
                    >
                        <input
                            onChange={(event) => {
                                const {value} = event.target;
                                setGameMaster({
                                    ...gameMaster,
                                    password: value
                                });
                            }}
                            className="input-fields"
                            type={"password"}
                            disabled={false}
                            value={gameMaster.password}
                            placeholder={"Enter password..."}
                        />
                    </FormRow>
                    <FormActionContainer>
                        <div className="col text-center mt-3">
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Enter"}
                                onClick={enterApp}
                                colour={ButtonColourOptions.Orange}
                            />
                        </div>
                    </FormActionContainer>
                </FormWrapper>
            </div>
        </React.Fragment>
    );
};

export default GameMasterLogin;
