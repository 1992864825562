import {Dispatch} from "redux";
import {GAME_STORE, GameDispatchTypes} from "./GameActionTypes";
import {postDataToServiceWithRedux} from "../../../modules/storeFetchWrappers/store/helpers/utils/StoreFetchWrappers";
import TenderGameApiModel from "../../apiModel/TenderGameApiModel";
import {CompanyUpdatePerRound, CustomerUpdatePerRound, GameStateChangeRequest} from "../../../api";
import {getAdminHeader, statusCodeCallback} from "../../apiModel/BaseApi";

export const nullifyGameStore = () => {
    return async (dispatch: Dispatch<GameDispatchTypes>) => {
        dispatch({
            type: GAME_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const startGameById = (gameId: number) => {
    return async (dispatch: Dispatch<GameDispatchTypes>) => {
        const request: GameStateChangeRequest = {
            gameId
        };
        try {
            return await postDataToServiceWithRedux(
                GAME_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().startGame(request, getAdminHeader()),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GAME_STORE.ERROR,
                loading: false,
                error: e
            });
            return false;
        }
    };
};

export const startNextRoundForGame = (gameId: number) => {
    return async (dispatch: Dispatch<GameDispatchTypes>) => {
        const request: GameStateChangeRequest = {
            gameId
        };
        try {
            return await postDataToServiceWithRedux(
                GAME_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().nextRound(request, getAdminHeader()),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GAME_STORE.ERROR,
                loading: false,
                error: e
            });
            return false;
        }
    };
};

export const endCurrentRoundForGame = (gameId: number) => {
    return async (dispatch: Dispatch<GameDispatchTypes>) => {
        const request: GameStateChangeRequest = {
            gameId
        };
        try {
            return await postDataToServiceWithRedux(
                GAME_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().endRound(request, getAdminHeader()),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GAME_STORE.ERROR,
                loading: false,
                error: e
            });
            return false;
        }
    };
};

export const endCurrentGame = (gameId: number) => {
    return async (dispatch: Dispatch<GameDispatchTypes>) => {
        const request: GameStateChangeRequest = {
            gameId
        };
        try {
            return await postDataToServiceWithRedux(
                GAME_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().endGame(request, getAdminHeader()),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GAME_STORE.ERROR,
                loading: false,
                error: e
            });
            return false;
        }
    };
};

export const updateCustomerPerRoundSettings = (request: CustomerUpdatePerRound) => {
    return async (dispatch: Dispatch<GameDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                GAME_STORE,
                dispatch,
                () =>
                    TenderGameApiModel.getTenderApi().updateCustomerPerRoundInfo(
                        request,
                        getAdminHeader()
                    ),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GAME_STORE.ERROR,
                loading: false,
                error: e
            });
            return false;
        }
    };
};

export const updateCompanyPerRoundSettings = (request: CompanyUpdatePerRound) => {
    return async (dispatch: Dispatch<GameDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                GAME_STORE,
                dispatch,
                () =>
                    TenderGameApiModel.getTenderApi().updateCompanyPerRoundInfo(
                        request,
                        getAdminHeader()
                    ),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GAME_STORE.ERROR,
                loading: false,
                error: e
            });
            return false;
        }
    };
};
