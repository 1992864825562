import React from "react";
import FormHeader from "../../../../../../Form/FormHeader";
import {CustomerBids, RoundTable} from "./helpers/previousRoundHelpers";
import RoundTableWrapper from "../../../../Shared/Components/RoundTable/RoundTableWrapper";

const PreviousRoundTable = (props: PreviousRoundTableProps) => {
    return (
        <React.Fragment>
            {props.showHeader && (
                <FormHeader
                    rowClassName={"mt-3"}
                    headerName={`Round ${props.roundNumber}`}
                    headerType={"h3"}
                    showRow
                />
            )}

            {sortCustomerAlphabetically(props.customerBids).map(
                (customerBid: CustomerBids, index) => {
                    return (
                        <React.Fragment key={index}>
                            <FormHeader
                                rowClassName={"mt-3"}
                                headerName={`Bids for ${customerBid.customerName}`}
                                headerType={"h5"}
                            />
                            <RoundTableWrapper
                                round={{
                                    number: props.roundNumber,
                                    bids: customerBid.bids
                                }}
                                showRankColumn={props.showRankColumn}
                                showPenaltyColumn={props.showPenaltyColumn}
                                showCustomerColumn={props.showCustomerColumn}
                                showCompanyColumn={props.showCompanyColumn}
                                showMaxRevenueColumn={props.showMaxRevenueColumn}
                                showMaxProfitColumn={props.showMaxProfitColumn}
                            />
                        </React.Fragment>
                    );
                }
            )}
        </React.Fragment>
    );
};

export default PreviousRoundTable;

interface PreviousRoundTableProps extends RoundTable {
    showHeader: boolean;
    showRankColumn: boolean;
    showPenaltyColumn: boolean;
    showCompanyColumn: boolean;
    showCustomerColumn: boolean;
    showMaxRevenueColumn: boolean;
    showMaxProfitColumn: boolean;
}

export function sortCustomerAlphabetically<T extends {customerName: string}>(items: T[]): T[] {
    return items.sort((a, b) => a.customerName.localeCompare(b.customerName));
}
