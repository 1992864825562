import React from "react";
import {RunningGameStatus} from "../../../../../api";
import FormRow from "../../../../Form/FormRow";

const SharedRoundDetails = (props: RunningGameStatus) => (
    <React.Fragment>
        <FormRow rowName={"Game Name:"} formRowClassName={"ml-3 mt-3"}>
            <p className="dark-blue-text">{props.name}</p>
        </FormRow>
        <FormRow rowName={"Game Description:"} formRowClassName={"ml-3"} columnClassName="pr-3">
            <p className="dark-blue-text overflow-wrap">{props.description}</p>
        </FormRow>
    </React.Fragment>
);

export default SharedRoundDetails;
