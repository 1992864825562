import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {routeNames} from "../routeNames";
import NewGameSettings from "../../Pages/GameSettings/NewGameSettings";
import UserLogin from "../../Pages/UserLogin/UserLogin";
import CustomRoute from "./CustomRoute";
import ViLayout from "../../Pages/Layouts/Layout/ViLayout";
import GameMasterLogin from "../../Pages/GameMasterLogin/GameMasterLogin";
import GameList from "../../Pages/GameList/GameList";
import GameMasterList from "../../Pages/GameMasterList/GameMasterList";
import AddGameMaster from "../../Pages/GameMaster/AddGameMaster";
import EditGameSettings from "../../Pages/GameSettings/EditGameSettings";
import CopyGameSettings from "../../Pages/GameSettings/CopyGameSettings";
import PlayerGameScreen from "../../Pages/GameScreens/Player/PlayerGameScreen";
import GameMasterGameScreen from "../../Pages/GameScreens/GameMaster/GameMasterGameScreen";
import CompleteGameScreen from "../../Pages/GameScreens/GameMaster/CompleteGameScreen";
import FourOhFour from "../../Pages/4xx/404/FourOhFour";
import FourOhThree from "../../Pages/4xx/403/FourOhThree";
import FourOhOne from "../../Pages/4xx/401/FourOhOne";
import {GuardProvider} from "react-router-guards";
import LoadingWheel from "../../../modules/stateHelpers/components/LoadingWheel";

const requireLogin = async (to: any, from: any, next: any) => {
    const fromPath = combinePathnameAndSearch(from.location.pathname, from.location.search);
    const toPath = combinePathnameAndSearch(to.location.pathname, to.location.search);
    const prevRoutesBlackList = [
        routeNames.fourOhOne.path,
        routeNames.fourOhThree.path,
        routeNames.fourOhFour.path,
        routeNames.userLogin.path,
        routeNames.gameMasterLogin.path
    ];

    const idx = prevRoutesBlackList.findIndex((route: string) => route === to.location.pathname);
    if (idx < 0) {
        if (fromPath != toPath) {
            localStorage.setItem("previous_route", fromPath);
        }
        localStorage.setItem("redirect_uri", toPath);
    }
    localStorage.setItem("current_route", toPath);

    next();
};

const Routes = () => {
    return (
        <GuardProvider guards={[requireLogin]} loading={LoadingWheel}>
            <Switch>
                <CustomRoute
                    exact
                    path={routeNames.gameSettingsNew.path}
                    layout={ViLayout}
                    component={NewGameSettings}
                />
                <CustomRoute
                    exact
                    path={`${routeNames.gameSettingsEdit.path}/:gameId`}
                    layout={ViLayout}
                    component={EditGameSettings}
                />
                <CustomRoute
                    exact
                    path={`${routeNames.gameSettingsCopy.path}/:gameIdToCopy`}
                    layout={ViLayout}
                    component={CopyGameSettings}
                />
                <CustomRoute
                    exact
                    path={routeNames.userLogin.path}
                    layout={ViLayout}
                    component={UserLogin}
                />
                <CustomRoute
                    exact
                    path={routeNames.gameMasterLogin.path}
                    layout={ViLayout}
                    component={GameMasterLogin}
                />
                <CustomRoute
                    exact
                    path={routeNames.gameList.path}
                    layout={ViLayout}
                    component={GameList}
                />
                <CustomRoute
                    exact
                    path={routeNames.gameMasterList.path}
                    layout={ViLayout}
                    component={GameMasterList}
                />
                <CustomRoute
                    exact
                    path={routeNames.gameMasterAdd.path}
                    layout={ViLayout}
                    component={AddGameMaster}
                />
                <CustomRoute
                    exact
                    path={routeNames.currentGame.path}
                    layout={ViLayout}
                    component={PlayerGameScreen}
                />
                <CustomRoute
                    exact
                    path={`${routeNames.currentGameGameMaster.path}/:gameId`}
                    layout={ViLayout}
                    component={GameMasterGameScreen}
                />
                <CustomRoute
                    exact
                    path={`${routeNames.completeGameGameMaster.path}/:gameId`}
                    layout={ViLayout}
                    component={CompleteGameScreen}
                />
                <CustomRoute
                    exact
                    path={routeNames.fourOhFour.path}
                    layout={ViLayout}
                    component={FourOhFour}
                />
                <CustomRoute
                    exact
                    path={routeNames.fourOhThree.path}
                    layout={ViLayout}
                    component={FourOhThree}
                />
                <CustomRoute
                    exact
                    path={routeNames.fourOhOne.path}
                    layout={ViLayout}
                    component={FourOhOne}
                />
                <Redirect to={routeNames.userLogin.path} />
            </Switch>
        </GuardProvider>
    );
};

export default Routes;

/** Splits the query strings from the href and will return a query parameter ready to be used with react router */
export function getQueryStringsFromUrl(url: string): string {
    const queries = url.split("?")[1];
    return queries ? queries : "";
}

/** Combines path and search string together */
export function combinePathnameAndSearch(pathname: string, search: string): string {
    return `${pathname}${search}`;
}
