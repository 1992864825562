import React from "react";
import {CustomerBids} from "../../PreviousRoundViews/helpers/previousRoundHelpers";
import FormHeader from "../../../../../../../Form/FormHeader";
import CurrentRoundTable from "./CurrentRoundTable";
import {sortCustomerAlphabetically} from "../../PreviousRoundViews/PreviousRoundTable";

const CurrentRoundTableWrapper = (props: CurrentRoundTableWrapperProps) => {
    return (
        <React.Fragment>
            {sortCustomerAlphabetically(props.customerBids).map(
                (customerBid: CustomerBids, index) => {
                    return (
                        <React.Fragment key={index}>
                            <FormHeader
                                rowClassName={"mt-3"}
                                headerName={`Bids for ${customerBid.customerName}`}
                                headerType={"h5"}
                            />
                            <CurrentRoundTable bids={customerBid.bids} />
                        </React.Fragment>
                    );
                }
            )}
        </React.Fragment>
    );
};

export default CurrentRoundTableWrapper;

interface CurrentRoundTableWrapperProps {
    customerBids: CustomerBids[];
}
