import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createNewBlankGameSettings} from "../../../store/gameSettings/actions/GameSettingsActions";
import EditGameSettingsForm from "./Components/EditGameSettingsForm";
import WithServiceState from "../../../modules/storeFetchWrappers/components/WithServiceState";
import {RootStore} from "../../../store/Store";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";

const ServiceWrapper = WithServiceState(EditGameSettingsForm);

const NewGameSettings = () => {
    const dispatch = useDispatch();
    const gameSettingsStore = useSelector((state: RootStore) => state.gameSettings);
    useEffect(() => {
        dispatch(createNewBlankGameSettings(3, 6, 6));
        const navItems: NavigationItem[] = [
            {
                name: routeNames.gameMasterList.name,
                path: routeNames.gameMasterList.path
            },
            {
                name: routeNames.gameList.name,
                path: routeNames.gameList.path
            }
        ];

        dispatch(setNavigationItems(navItems));
    }, []);

    return (
        <React.Fragment>
            <ServiceWrapper showErrorMessage={false} {...gameSettingsStore} />
        </React.Fragment>
    );
};

export default NewGameSettings;
