import React from "react";
import {RunningGameStatus} from "../../../../../../api";
import FormWrapper from "../../../../../Form/FormWrapper";
import PreviousRoundViews from "../InRound/PreviousRoundViews/PreviousRoundViews";
import BetweenRoundActions from "./Actions/BetweenRoundActions";
import SharedRoundDetails from "../../../Player/Components/SharedRoundDetails";
import FormHeader from "../../../../../Form/FormHeader";

const BetweenRounds = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                <BetweenRoundActions {...props} />
            </FormWrapper>
            <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                <FormHeader
                    rowClassName={"mt-3"}
                    headerName={`Game Information`}
                    headerType={"h2"}
                    showRow
                />
                <SharedRoundDetails {...props} />
            </FormWrapper>
            {/*<FormWrapper wrapperClassName={"mt-3 mb-3"}>*/}
            {/*    <PerCompanySettings {...props} />*/}
            {/*</FormWrapper>*/}
            {/*<FormWrapper wrapperClassName={"mt-3 mb-3"}>*/}
            {/*    <PerCustomerSettings {...props} />*/}
            {/*</FormWrapper>*/}
            <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                <React.Fragment>
                    {props.round > 0 && <PreviousRoundViews {...props} />}
                </React.Fragment>
            </FormWrapper>
        </React.Fragment>
    );
};

export default BetweenRounds;
