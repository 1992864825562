import React from "react";
import {Company, GameStep} from "../../../../../api";
import FormRow from "../../../../Form/FormRow";
import {CurrentTab} from "../../Helpers/gameSettingsHelpers";
import {useDispatch, useSelector} from "react-redux";
import {
    setCompanyCostPerGoodsSettings,
    setCompanyName,
    setCompanySettings
} from "../../../../../store/gameSettings/actions/GameSettingsActions";
import FormHeader from "../../../../Form/FormHeader";
import CompanyPerRoundSettingsTable from "./PerRoundSettings/CompanyPerRoundSettingsTable";
import FormError from "../../../../Form/FormError";
import {RootStore} from "../../../../../store/Store";
import {useGameState} from "../../../../Hooks/useGameState";
import CharacterLimitWarning from "../../../../Form/CharacterLimitWarning";
import {clamp} from "../../../../../utils/mathUtils";

const ActiveCompanyTab = (props: CurrentTab<Company>) => {
    const dispatch = useDispatch();
    const rounds = useSelector((state: RootStore) => state.gameSettings.data?.numberRounds || 0);
    const gameId = useSelector((state: RootStore) => state.gameSettings.data?.id || 0);
    const companies = useSelector((state: RootStore) => state.gameSettings.data?.companies || []);
    const numberOfRounds = useSelector(
        (state: RootStore) => state.gameSettings.data?.numberRounds || 0
    );
    const gameState = useGameState();

    const isCompanyNameUnique = () => {
        const companyNames = companies.map((comp: Company) => comp.name);

        for (let i = 0; i < companyNames.length; ++i) {
            const companyName = companyNames[i];

            if (i === props.tabIndex) continue;

            if (companyName === props.tab.name) return false;
        }

        return true;
    };

    return (
        <React.Fragment>
            <FormRow rowName={"Company Name:"} formRowClassName={"mt-3"} rowClassName={"ml-0 mr-3"}>
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.name}
                        onChange={(event) => {
                            const {value} = event.target;
                            if (value.length > 256) return;
                            dispatch(
                                setCompanyName({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        name: event.target.value
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter company name...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"text"}
                    />
                    <CharacterLimitWarning characterLimit={256} textValue={props.tab.name} />
                    {props.tab.name.length < 3 && (
                        <FormError
                            errorMessage={"Company name must be more than 3 characters long!"}
                        />
                    )}
                    {!isCompanyNameUnique() && (
                        <FormError errorMessage={"Company name is not unique"} />
                    )}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Company Description:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
            >
                <React.Fragment>
                    <textarea
                        className="input-fields"
                        style={{minHeight: `150px`}}
                        value={props.tab.description}
                        onChange={(event) => {
                            const {value} = event.target;
                            const maxLength = 512;
                            dispatch(
                                setCompanySettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        description: value.substring(0, maxLength)
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter company description...."}
                        disabled={gameState !== GameStep.PendingStart}
                    />
                    <CharacterLimitWarning
                        characterLimit={512}
                        textValue={props.tab.description || ""}
                    />
                    {!props.tab.description && (
                        <FormError errorMessage={"Company description is required!"} />
                    )}
                </React.Fragment>
            </FormRow>
            {/*<FormRow*/}
            {/*    rowName={"Base Sourcing Limit:"}*/}
            {/*    formRowClassName={"mt-3"}*/}
            {/*    rowClassName={"ml-0 mr-3"}*/}
            {/*    showTooltip*/}
            {/*    tooltipText={"Number of packs the company can source in the first round."}*/}
            {/*>*/}
            {/*    <React.Fragment>*/}
            {/*        <input*/}
            {/*            className="input-fields"*/}
            {/*            value={props.tab.baseSourcingLimit}*/}
            {/*            onChange={(event) => {*/}
            {/*                dispatch(*/}
            {/*                    setCompanySettings({*/}
            {/*                        index: props.tabIndex,*/}
            {/*                        company: {*/}
            {/*                            ...props.tab,*/}
            {/*                            baseSourcingLimit: +event.target.value*/}
            {/*                        }*/}
            {/*                    })*/}
            {/*                );*/}
            {/*            }}*/}
            {/*            placeholder={"Enter base sourcing limit...."}*/}
            {/*            disabled={gameState !== GameStep.PendingStart}*/}
            {/*            type={"number"}*/}
            {/*        />*/}
            {/*        {getBaseSourcingLimitError(props.tab.baseSourcingLimit)}*/}
            {/*    </React.Fragment>*/}
            {/*</FormRow>*/}
            {/*<FormRow*/}
            {/*    rowName={"Sourcing Limit Rate:"}*/}
            {/*    formRowClassName={"mt-3"}*/}
            {/*    rowClassName={"ml-0 mr-3"}*/}
            {/*    showTooltip*/}
            {/*    tooltipText={*/}
            {/*        "Default multiplier per round for the source limit. 1 = No change, 0.5 = Half limit, 2 = Double limit."*/}
            {/*    }*/}
            {/*>*/}
            {/*    <React.Fragment>*/}
            {/*        <input*/}
            {/*            className="input-fields"*/}
            {/*            value={props.tab.sourcingLimitRate}*/}
            {/*            onChange={(event) => {*/}
            {/*                dispatch(*/}
            {/*                    setCompanySettings({*/}
            {/*                        index: props.tabIndex,*/}
            {/*                        company: {*/}
            {/*                            ...props.tab,*/}
            {/*                            sourcingLimitRate: +event.target.value*/}
            {/*                        }*/}
            {/*                    })*/}
            {/*                );*/}
            {/*            }}*/}
            {/*            placeholder={"Enter sourcing limit rate...."}*/}
            {/*            disabled={gameState !== GameStep.PendingStart}*/}
            {/*            type={"number"}*/}
            {/*        />*/}
            {/*        {getSourcingLimitRateError(props.tab.sourcingLimitRate)}*/}
            {/*    </React.Fragment>*/}
            {/*</FormRow>*/}
            {/*<FormRow*/}
            {/*    rowName={"Cost Of Goods Per Pack:"}*/}
            {/*    formRowClassName={"mt-3"}*/}
            {/*    rowClassName={"ml-0 mr-3"}*/}
            {/*    showTooltip*/}
            {/*    tooltipText={"Cost for a single pack, in the first round"}*/}
            {/*>*/}
            {/*    <React.Fragment>*/}
            {/*        <input*/}
            {/*            className="input-fields"*/}
            {/*            value={props.tab.baseCostPerPack}*/}
            {/*            onChange={(event) => {*/}
            {/*                dispatch(*/}
            {/*                    setCompanySettings({*/}
            {/*                        index: props.tabIndex,*/}
            {/*                        company: {*/}
            {/*                            ...props.tab,*/}
            {/*                            baseCostPerPack: +event.target.value*/}
            {/*                        }*/}
            {/*                    })*/}
            {/*                );*/}
            {/*            }}*/}
            {/*            placeholder={"Enter cost of goods per pack...."}*/}
            {/*            disabled={gameState !== GameStep.PendingStart}*/}
            {/*            type={"number"}*/}
            {/*        />*/}
            {/*        {getCostOfGoodsPerPack(props.tab.baseCostPerPack)}*/}
            {/*    </React.Fragment>*/}
            {/*</FormRow>*/}
            <FormRow
                rowName={"Cost Of Goods:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={
                    "Default multiplier per round for the cost per goods. 1 = No change, 0.5 = Half cost, 2 = Double cost."
                }
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.costPerPackRate}
                        onChange={(event) => {
                            const {value} = event.target;
                            dispatch(
                                setCompanyCostPerGoodsSettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        costPerPackRate: clamp(+value, 1, 100)
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter cost of goods rate...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {getCostPerGoodsRateError(props.tab.costPerPackRate)}
                </React.Fragment>
            </FormRow>
            {/*<FormRow*/}
            {/*    rowName={"Base Clinical Units Per Pack:"}*/}
            {/*    formRowClassName={"mt-3"}*/}
            {/*    rowClassName={"ml-0 mr-3"}*/}
            {/*    showTooltip*/}
            {/*    tooltipText={*/}
            {/*        "The number of clinical units in a single pack. 28 = 28 units in 1 pack, 0.5 = 2 packs for 1 clinical unit."*/}
            {/*    }*/}
            {/*>*/}
            {/*    <React.Fragment>*/}
            {/*        <input*/}
            {/*            className="input-fields"*/}
            {/*            value={props.tab.baseClinicalUnitsPerPack}*/}
            {/*            onChange={(event) => {*/}
            {/*                dispatch(*/}
            {/*                    setCompanySettings({*/}
            {/*                        index: props.tabIndex,*/}
            {/*                        company: {*/}
            {/*                            ...props.tab,*/}
            {/*                            baseClinicalUnitsPerPack: +event.target.value*/}
            {/*                        }*/}
            {/*                    })*/}
            {/*                );*/}
            {/*            }}*/}
            {/*            placeholder={"Enter base clinical units per pack...."}*/}
            {/*            disabled={gameState !== GameStep.PendingStart}*/}
            {/*            step={0.01}*/}
            {/*            type={"number"}*/}
            {/*        />*/}
            {/*        {getBaseClinicalUnitsPerPackError(props.tab.baseClinicalUnitsPerPack)}*/}
            {/*    </React.Fragment>*/}
            {/*</FormRow>*/}
            <FormRow
                rowName={"Starting Round:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={"The first round that the company will appear."}
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.startingRound}
                        onChange={(event) => {
                            const {value} = event.target;
                            dispatch(
                                setCompanySettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        startingRound: clamp(+value, 1, numberOfRounds)
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter starting round...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {gameState === GameStep.PendingStart && (
                        <React.Fragment>
                            {getRoundError(props.tab.startingRound, rounds)}
                        </React.Fragment>
                    )}
                </React.Fragment>
            </FormRow>
            {gameId > 0 && gameState === GameStep.PendingStart && (
                <FormRow
                    rowName={"Access Code:"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                    showTooltip
                    tooltipText={
                        "The code a player will use to play as this company, within this game. Autogenerated, always populated on reads."
                    }
                >
                    <h6 className="mb-0 orange-text">
                        {props.tab.accessCode
                            ? props.tab.accessCode
                            : "Game has not started yet. Code will appear when the game has started!"}
                    </h6>
                </FormRow>
            )}
            <FormHeader
                headerName={"Per Round Settings"}
                headerType={"h5"}
                rowClassName="mt-3"
                showRow
            />
            <CompanyPerRoundSettingsTable
                perRoundSettings={props.tab.rounds}
                companyName={props.tab.name}
                startingRound={props.tab.startingRound}
            />
        </React.Fragment>
    );
};

export default ActiveCompanyTab;

function getBaseSourcingLimitError(baseSourcingLimit: number) {
    if (baseSourcingLimit < 0) {
        return <FormError errorMessage={"Base sourcing limit cannot be less than 0"} />;
    }
    if (baseSourcingLimit > 10000) {
        return <FormError errorMessage={"Base sourcing limit cannot be more than 10000"} />;
    }
}
function getSourcingLimitRateError(sourcingLimitRate?: number) {
    if (!sourcingLimitRate) return;

    if (sourcingLimitRate < 0) {
        return <FormError errorMessage={"Sourcing limit cannot be less than 0"} />;
    }
    if (sourcingLimitRate > 100) {
        return <FormError errorMessage={"Sourcing limit cannot be more than 100"} />;
    }
}
function getCostOfGoodsPerPack(costOfGoodsPerPack?: number) {
    if (!costOfGoodsPerPack) return;

    if (costOfGoodsPerPack < 0) {
        return <FormError errorMessage={"Cost of goods per pack cannot be less than 0"} />;
    }
    if (costOfGoodsPerPack > 100) {
        return <FormError errorMessage={"Cost of goods per pack cannot be more than 100"} />;
    }
}
function getCostPerGoodsRateError(costPerGoodsRate?: number) {
    if (!costPerGoodsRate) return;

    if (costPerGoodsRate < 0) {
        return <FormError errorMessage={"Cost per goods cannot be less than 0"} />;
    }
    if (costPerGoodsRate > 100) {
        return <FormError errorMessage={"Cost per goods cannot be more than 100"} />;
    }
}
function getBaseClinicalUnitsPerPackError(baseClinicalUnitsPerPack?: number) {
    if (!baseClinicalUnitsPerPack) return;

    if (baseClinicalUnitsPerPack === 0) {
        return <FormError errorMessage={"Base clinical units per pack cannot be less than 0.01"} />;
    }
    if (baseClinicalUnitsPerPack < 0.01) {
        return <FormError errorMessage={"Base clinical units per pack cannot be less than 0.01"} />;
    }
    if (baseClinicalUnitsPerPack > 1000) {
        return <FormError errorMessage={"Base clinical units per pack cannot be more than 1000"} />;
    }
}

function getRoundError(numberRounds: number, maxNumberOfRounds: number) {
    if (numberRounds <= 0) {
        return <FormError errorMessage={"Round must be more than 0"} />;
    }
    if (numberRounds > maxNumberOfRounds) {
        return <FormError errorMessage={`Round cannot exceed more than ${maxNumberOfRounds}`} />;
    }
}
