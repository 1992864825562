import React from "react";
import CompleteRoundTableDataHeader from "./CompleteRoundTableDataHeader";
import FormHeader from "../../../../../../Form/FormHeader";
import CompleteRoundTableRankingHeader from "./CompleteRoundTableRankingHeader";

const CompleteRoundTable = (props: CompleteRoundTableProps) => {
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={props.headerName}
                headerType={"h4"}
                showRow
            />
            <div className="pb-2 mt-3 ml-3 mr-3">
                <div className="d-block table-overflow-x vi-table-wrapper">
                    <table className="vi-table fadeIn">
                        {props.headerType === "Data" ? (
                            <CompleteRoundTableDataHeader
                                showExtraColumn={props.showExtraColumn}
                                extraColumnName={props.extraColumnName}
                            />
                        ) : (
                            <CompleteRoundTableRankingHeader
                                showExtraColumn={props.showExtraColumn}
                                extraColumnName={props.extraColumnName}
                            />
                        )}
                        <tbody>{props.children}</tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CompleteRoundTable;

interface CompleteRoundTableProps {
    children: any;
    headerName: string;
    showExtraColumn?: boolean;
    extraColumnName?: string;
    headerType: HeaderType;
}

type HeaderType = "Data" | "Ranking";
