import React from "react";
import {GameListOrder, GameListRequest, GameTitle} from "../../../../api";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import Tooltip from "../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../Icon/Icon";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {useModal} from "../../../../modules/modal/hooks/useModal";
import Modal from "../../../../modules/modal/components/Modal";
import {ModalSize} from "../../../../modules/modal/helpers/modalHelpers";
import FormRowWithText from "../../../Form/FormRowWithText";
import FormActionContainer from "../../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../Button/VIButton";
import {deleteGameById} from "../../../../store/gameSettings/actions/GameSettingsActions";
import {useQuery} from "../../../Hooks/useQuery";
import moment from "moment";
import {pagedRequestConfig} from "../../../Filters/helpers/filterHelpers";
import {getSortOrderFromString} from "../../../../utils/enumUtils";
import {getGameListings} from "../../../../store/gameList/actions/GameListActions";
import {getUiFriendlyText} from "../../../../utils/textUtils";

const GameListTableRow = (props: GameTitle) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const {isShown, toggle} = useModal();

    const editGame = () => {
        const path = `${routeNames.gameSettingsEdit.path}/${props.id}`;
        history.push({
            pathname: path,
            search: window.location.search
        });
    };

    const deleteGameSettings = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteGameById(props.id));

        if (success) {
            const request = getGameListRequest();
            dispatch(getGameListings(request));
            toggle();
        }
    };

    const getGameListRequest = (): GameListRequest => {
        const minDate = query.get("minDate");
        const sortOrder = query.get("sortOrder");
        const startDate = getStartDate(minDate);
        return {
            pageNum: 0,
            numPerPage: pagedRequestConfig.resultsPerPage,
            startDate: startDate.unix(),
            endDate: startDate.clone().endOf("month").unix(),
            order: sortOrder ? getSortOrderFromString(sortOrder) : GameListOrder.CreatedDateDesc
        };
    };

    const getStartDate = (minDate: string | null) => {
        if (!minDate) {
            return moment().startOf("month");
        }
        return moment.unix(+minDate).startOf("month");
    };

    return (
        <React.Fragment>
            <tr className="vi-table-row">
                <td align="left" className="vi-table-row-item-item mw-20 p-3">
                    {props.name}
                </td>
                <td align="left" className="vi-table-row-item-item mw-20 p-3">
                    {formatUnixToDDMMYYYY(props.dateCreated)}
                </td>
                <td align="left" className="vi-table-row-item-item mw-20 p-3">
                    {getUiFriendlyText(props.step)}
                </td>
                <td align="left" className="vi-table-row-item-item mw-20 p-3">
                    <div className="row">
                        <div className="icon-tooltip-wrapper-md mr-3">
                            <Tooltip
                                tooltipText={`Edit ${props.name}`}
                                size={"md"}
                                place={"left"}
                                rootElement={"span"}
                            >
                                <Icon
                                    rootElement={"Div"}
                                    icon={IconType.Edit}
                                    size={"Medium"}
                                    className="icon-dark"
                                    onClick={editGame}
                                />
                            </Tooltip>
                        </div>
                        <div className="icon-tooltip-wrapper-md mr-3">
                            <Tooltip
                                tooltipText={`Delete ${props.name}?`}
                                size={"md"}
                                place={"left"}
                                rootElement={"span"}
                            >
                                <Icon
                                    rootElement={"Div"}
                                    icon={IconType.Bin}
                                    size={"Medium"}
                                    className="icon-dark"
                                    onClick={toggle}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </td>
            </tr>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={"Delete Game Setting"}
                modalSize={ModalSize.extra_small}
            >
                <React.Fragment>
                    <FormRowWithText rowName={"Are you sure you want to delete this setting?"} />
                    <FormActionContainer>
                        <div className="col mt-3">
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={deleteGameSettings}
                                colour={ButtonColourOptions.Orange}
                            />
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </FormActionContainer>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default GameListTableRow;
