import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMyGame} from "../../../../store/runningGameStatus/actions/RunningGameStatusActions";
import WithServiceState from "../../../../modules/storeFetchWrappers/components/WithServiceState";
import PlayerGameWrapper from "./Components/PlayerGameWrapper";
import useInterval from "../../../Hooks/useInterval";
import {RootStore} from "../../../../store/Store";
import {useQuery} from "../../../Hooks/useQuery";
import {getEncodedToken} from "../../../../store/apiModel/BaseApi";
import {setBase64Code} from "../../../../store/user/actions/UserActions";

const ServiceWrapper = WithServiceState(PlayerGameWrapper);

const PlayerGameScreen = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const runningGameStore = useSelector((state: RootStore) => state.runningGameStatus);

    useEffect(() => {
        const accessCodeQuery = query.get("accessCode");
        if (accessCodeQuery) {
            addUserAccessCode(accessCodeQuery);
        }

        (async function getGameStatus() {
            await fetchData();
        })();
    }, []);

    const addUserAccessCode = (code: string) => {
        if (code.length === 0) return;

        const base64EncodedAuth = getEncodedToken(code, code);
        dispatch(setBase64Code(base64EncodedAuth));
    };

    useInterval(async () => {
        await fetchData();
    }, 2000);

    const fetchData = async () => {
        dispatch(getMyGame());
    };
    return (
        <React.Fragment>
            <div className="container fadeIn">
                <ServiceWrapper showErrorMessage={false} {...runningGameStore} loading={false} />
            </div>
        </React.Fragment>
    );
};

export default PlayerGameScreen;
