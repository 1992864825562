import {Dispatch} from "redux";
import {USER_STORE, UserDispatchTypes} from "./UserActionTypes";

export const nullifyUserStore = () => {
    return async (dispatch: Dispatch<UserDispatchTypes>) => {
        dispatch({
            type: USER_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const setBase64Code = (value: string) => {
    return async (dispatch: Dispatch<UserDispatchTypes>) => {
        dispatch({
            type: USER_STORE.SUCCESS,
            loading: false,
            error: null,
            data: value
        });
    };
};
