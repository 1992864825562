import React from "react";
import {CustomerPerRound, GameStep} from "../../../../../../api";
import {useDispatch} from "react-redux";
import {useGameState} from "../../../../../Hooks/useGameState";
import {setPerRoundCustomerSettings} from "../../../../../../store/gameSettings/actions/GameSettingsActions";
import {clamp} from "../../../../../../utils/mathUtils";
import Table from "../../../../../Table/PulseTable";
import FormError from "../../../../../Form/FormError";

const CustomerPerRoundSettingsTable = (props: CustomerPerRoundSettingsTableProps) => {
    const dispatch = useDispatch();
    const gameState = useGameState();
    return (
        <React.Fragment>
            <Table
                items={toTableRow(props)}
                headers={{
                    roundNumber: "Round Number",
                    round: "Sourcing Limit"
                }}
                customRenderers={{
                    round: (item: TableRow) => {
                        return (
                            <React.Fragment>
                                <input
                                    className="input-fields"
                                    value={item.round.volumeRequirement}
                                    onChange={(event) => {
                                        const {value} = event.target;
                                        dispatch(
                                            setPerRoundCustomerSettings({
                                                customerPerRound: {
                                                    ...item.round,
                                                    volumeRequirement: clamp(+value, 1, 1000000)
                                                },
                                                roundSettingIndex: item.roundNumber - 1,
                                                customerName: props.customerName
                                            })
                                        );
                                    }}
                                    placeholder={"Enter volume requirement..."}
                                    disabled={gameState !== GameStep.PendingStart}
                                    type={"number"}
                                />
                                {getVolumeRequirementError(item.round.volumeRequirement)}
                            </React.Fragment>
                        );
                    }
                }}
            />
            {/*{props.perRoundSettings.length > 0 ? (*/}
            {/*    <React.Fragment>*/}
            {/*        <div className="mt-3 pb-5 ml-3 mr-3">*/}
            {/*            <div className="d-block table-overflow-x vi-table-wrapper">*/}
            {/*                <table className="vi-table fadeIn">*/}
            {/*                    <CompanyPerRoundSettingsTableHeader />*/}
            {/*                    <tbody>*/}
            {/*                        {props.perRoundSettings.map(*/}
            {/*                            (item: CustomerPerRound, index: number) => {*/}
            {/*                                return (*/}
            {/*                                    <CustomerPerRoundSettingsTableRow*/}
            {/*                                        perRoundSettings={item}*/}
            {/*                                        customerIndex={props.companyIndex}*/}
            {/*                                        customerPerRoundIndex={index}*/}
            {/*                                        key={index}*/}
            {/*                                    />*/}
            {/*                                );*/}
            {/*                            }*/}
            {/*                        )}*/}
            {/*                    </tbody>*/}
            {/*                </table>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </React.Fragment>*/}
            {/*) : (*/}
            {/*    <div className="row ml-0 mr-0 fadeIn mt-3">*/}
            {/*        <div className="col">*/}
            {/*            <h6 className="text-center orange-text">*/}
            {/*                /!* eslint-disable-next-line react/no-unescaped-entities *!/*/}
            {/*                There are no per round customer settings, click "Add Row" to add per*/}
            {/*                round settings.*/}
            {/*            </h6>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{gameState === GameStep.PendingStart && (*/}
            {/*    <FormActionContainer>*/}
            {/*        <div className="col text-center">*/}
            {/*            <VIButton*/}
            {/*                roundedCorner*/}
            {/*                size={ButtonSize.Large}*/}
            {/*                innerValue={"Add Row"}*/}
            {/*                onClick={() =>*/}
            {/*                    dispatch(addPerRoundCustomerSettings(props.companyIndex))*/}
            {/*                }*/}
            {/*                colour={ButtonColourOptions.Orange}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </FormActionContainer>*/}
            {/*)}*/}
        </React.Fragment>
    );
};

export default CustomerPerRoundSettingsTable;

interface CustomerPerRoundSettingsTableProps {
    perRoundSettings: CustomerPerRound[];
    customerName: string;
}

interface TableRow {
    roundNumber: number;
    round: CustomerPerRound;
}

function toTableRow(props: CustomerPerRoundSettingsTableProps): TableRow[] {
    return props.perRoundSettings.map((item) => {
        return {
            roundNumber: item.round,
            round: item
        };
    });
}

function getVolumeRequirementError(requirementError: number) {
    if (requirementError === 0) {
        return <FormError errorMessage={"Volume requirement must be more than 0"} />;
    }
    if (requirementError > 1000000) {
        return <FormError errorMessage={"Volume requirement must be more than 1,000,000"} />;
    }
}
