import React from "react";
import {GameStep, RunningGameStatus} from "../../../../../../api";
import FormRow from "../../../../../Form/FormRow";
import {capitalizeFirstLetter} from "../../../../../../utils/textUtils";

const RoundTime = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormRow rowName={"Round Duration (Minutes):"} formRowClassName={"ml-3"}>
                <p className="dark-blue-text">{getTimeForNextRound(props)}</p>
            </FormRow>
        </React.Fragment>
    );
};

export default RoundTime;

export function getTimeForNextRound(args: RunningGameStatus): number {
    const roundNumber =
        capitalizeFirstLetter(args.step) === GameStep.PendingStart ? args.round + 1 : args.round;
    const index = args.roundConfigs.findIndex((item) => item.round === roundNumber);

    if (index < 0) return args.timePerRoundMins;

    return args.roundConfigs[index].roundTimeMins || args.timePerRoundMins;
}
