import React, {useMemo} from "react";
import {RunningGameStatus} from "../../../../../../../api";
import {getMaximalRevenueProfitsForGame} from "../DataTables/DataTables";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
} from "chart.js";
import {Line} from "react-chartjs-2";
import FormHeader from "../../../../../../Form/FormHeader";
import {defaultColours} from "../../../Components/InRound/PreviousRoundViews/PreviousRoundStats";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "bottom" as const
        }
    }
};

const ValueLostAreaChart = (props: RunningGameStatus) => {
    const data = useMemo(() => convertToLostValueAreaChart(props), []);
    return (
        <React.Fragment>
            <FormHeader headerName={`Value Lost`} headerType={"h4"} showRow rowClassName="mt-3" />
            <Line options={options} data={data} />
        </React.Fragment>
    );
};

export default ValueLostAreaChart;

function convertToLostValueAreaChart(props: RunningGameStatus): ValueLostAreaGraphProps {
    const labels = ["Start", ...getRoundLabels(props.numberRounds)];
    return {
        labels,
        datasets: getLostValue(props)
    };
}

export function getRoundLabels(numberOfRounds: number): string[] {
    const rounds: string[] = [];
    for (let i = 0; i < numberOfRounds; ++i) {
        rounds.push(`Round ${i + 1}`);
    }

    return rounds;
}

function getLostValue(props: RunningGameStatus): ValueLostProps[] {
    const maximalRevenue = getMaximalRevenueProfitsForGame(props, "MaxRevenue");
    const remainingRevenue = getMaximalRevenueProfitsForGame(props, "Revenue");
    return [
        {
            fill: true,
            label: "Remaining Value",
            data: remainingRevenue.map((item) => item.value),
            borderColor: "rgb(128,89,0)",
            backgroundColor: defaultColours[0]
        },
        {
            fill: true,
            label: "Maximal Value",
            data: maximalRevenue.map((item) => item.value),
            borderColor: "rgb(28,67,77)",
            backgroundColor: defaultColours[1]
        }
    ];
}

interface ValueLostAreaGraphProps {
    labels: string[];
    datasets: ValueLostProps[];
}

interface ValueLostProps {
    fill: boolean;
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
}
