import React from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import FormHeader from "../../../../../Form/FormHeader";
import {Customer} from "../../../../../../api";
import PerceivedValueItem from "./PerceivedValueItem";

const PerceivedValues = (props: Customer) => {
    const companies = useSelector((state: RootStore) => state.gameSettings.data?.companies || []);
    return (
        <React.Fragment>
            <FormHeader
                headerName={"Company Perceived Values"}
                headerType={"h5"}
                rowClassName="mt-3"
                showRow
            />
            {companies.map((company, index) => {
                return <PerceivedValueItem customer={props} company={company} key={index} />;
            })}
        </React.Fragment>
    );
};

export default PerceivedValues;
