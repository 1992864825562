import React from "react";
import {GameStep, RunningGameStatus} from "../../../../../../api";
import FormWrapper from "../../../../../Form/FormWrapper";
import FormHeader from "../../../../../Form/FormHeader";
import FormRow from "../../../../../Form/FormRow";
import CustomerNextRoundInfo from "../../../Shared/Components/CustomerNextRoundInfo/CustomerNextRoundInfo";
import {capitalizeFirstLetter} from "../../../../../../utils/textUtils";
import {getMyGame} from "../../../../../../store/runningGameStatus/actions/RunningGameStatusActions";
import Timer from "../../../../../Timer/Timer";
import {useDispatch} from "react-redux";
import SharedGameInformation from "../Shared/SharedGameInformation";
import {getTimeForNextRound} from "../../../Shared/Components/RoundTime/RoundTime";
import StartingRoundCompanyInformation from "../PendingStart/Components/StartingRoundCompanyInformation";
import StartingRoundCustomerInformation from "../PendingStart/Components/StartingRoundCustomerInformation";

const NotInRoundScreen = (props: RunningGameStatus) => {
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <FormWrapper>
                <React.Fragment>
                    <SharedGameInformation {...props} />
                    {capitalizeFirstLetter(props.step) === GameStep.InRound && (
                        <FormRow
                            rowName={"Time Remaining for current round:"}
                            formRowClassName={"ml-3"}
                            rowClassName={"mb-2"}
                        >
                            <Timer
                                showToasts={false}
                                roundStart={props.rounds[0].roundStartDate || 0}
                                roundDuration={getTimeForNextRound(props)}
                                //Poll the service, the game would have ended. Get info for the game screen
                                onTimerFinishedCallback={() => dispatch(getMyGame())}
                            />
                        </FormRow>
                    )}
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Game Information For ${props.companies[0].name}`}
                        headerType={"h2"}
                        showRow
                    />
                    <FormRow rowName={""} formRowClassName={"ml-3 pt-3 pr-3"}>
                        <p className="dark-blue-text">{getTextForUser(props)}</p>
                    </FormRow>
                    <StartingRoundCompanyInformation {...props} />
                    <StartingRoundCustomerInformation {...props} />
                    <CustomerNextRoundInfo gameStatus={props} showNextRoundText={false} />
                </React.Fragment>
            </FormWrapper>
        </React.Fragment>
    );
};

export default NotInRoundScreen;

export function getTextForUser(props: RunningGameStatus): string {
    switch (capitalizeFirstLetter(props.step)) {
        case GameStep.BetweenRounds:
            return getBetweenRoundsText(props);
        default:
            return `The Game Master has ruled that your company will start in round ${props.companies[0].startingRound}. The current round is ${props.round}. Please wait for the game to get to the starting round for your company. Any questions, please ask the Game Master!`;
    }
}

function getBetweenRoundsText(props: RunningGameStatus): string {
    const nextRound = props.round + 1;

    if (nextRound === props.companies[0].startingRound) {
        return `You are starting in the next round! Below are the details for the next round.`;
    }

    return `The Game Master has ruled that your company will start in round ${props.companies[0].startingRound}. The current round is ${props.round}. Please wait for the game to get to the starting round for your company. Any questions, please ask the Game Master!`;
}
