import {Dispatch} from "redux";
import {GAME_LIST_STORE, GameListDispatchTypes} from "./GameListActionTypes";
import {GameListRequest} from "../../../api";
import {postDataToServiceWithRedux} from "../../../modules/storeFetchWrappers/store/helpers/utils/StoreFetchWrappers";
import TenderGameApiModel from "../../apiModel/TenderGameApiModel";
import {getAdminHeader, statusCodeCallback} from "../../apiModel/BaseApi";
import {decapitalizeFirstLetter} from "../../../utils/textUtils";

export const nullifyGameListStore = () => {
    return async (dispatch: Dispatch<GameListDispatchTypes>) => {
        dispatch({
            type: GAME_LIST_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const getGameListings = (request: GameListRequest) => {
    return async (dispatch: Dispatch<GameListDispatchTypes>) => {
        const fixedGameRequest: GameListRequest = {
            ...request,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            order: decapitalizeFirstLetter(request.order)
        };
        try {
            return await postDataToServiceWithRedux(
                GAME_LIST_STORE,
                dispatch,
                () =>
                    TenderGameApiModel.getTenderApi().listGames(fixedGameRequest, getAdminHeader()),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GAME_LIST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
