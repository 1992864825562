import React, {useEffect, useState} from "react";
import {Game, GameStep} from "../../../../api";
import GeneralSettings from "./General/GeneralSettings";
import CustomerSettings from "./Customer/CustomerSettings";
import CompaniesSettings from "./Companies/CompaniesSettings";
import FormActionContainer from "../../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../Button/VIButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {useDispatch} from "react-redux";
import {
    createCopyOfGame,
    nullifyGameSettingsStore,
    saveGameToService,
    setLocalSettings
} from "../../../../store/gameSettings/actions/GameSettingsActions";
import AccessCodeList from "./AccessCode/AccessCodeList";
import GameState from "./GameState/GameState";
import {showErrorToast, showSuccessToast} from "../../../../utils/toastUtils";
import {startGameById} from "../../../../store/game/actions/GameActions";
import {useModal} from "../../../../modules/modal/hooks/useModal";
import Modal from "../../../../modules/modal/components/Modal";
import {ModalSize} from "../../../../modules/modal/helpers/modalHelpers";
import FormRow from "../../../Form/FormRow";
import {validateCompany} from "../../../../store/gameSettings/helpers/gameSettingsHelpers";
import FormWrapper from "../../../Form/FormWrapper";
import FormSection from "../../../Form/FormSection";

const EditGameSettingsForm = (props: Game) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();
    const [copiedGameName, setCopiedGameName] = useState<string>("");

    useEffect(() => {
        return () => {
            dispatch(nullifyGameSettingsStore());
        };
    }, []);

    useEffect(() => {
        if (!isShown) {
            setCopiedGameName("");
        }
    }, [isShown]);

    const backToGameListing = () => {
        history.push(routeNames.gameList.path);
    };

    const saveAndStart = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const game: Game | undefined | null = await dispatch(saveGameToService(props));

        if (!game) {
            return;
        }
        dispatch(setLocalSettings(game));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const started: boolean = await dispatch(startGameById(game.id));

        if (!started) {
            showErrorToast("Could not start game.");
            return;
        }
        const path = `${routeNames.currentGameGameMaster.path}/${game.id}`;
        history.replace(path);
    };

    const viewCurrentGame = () => {
        const path = `${routeNames.currentGameGameMaster.path}/${props.id}`;
        history.replace(path);
    };

    const saveChanges = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const game: Game | undefined | null = await dispatch(saveGameToService(props));

        if (!game) {
            return;
        }
        dispatch(setLocalSettings(game));
        showSuccessToast("Saved Changes");
    };

    const saveAndBackToListing = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const game: Game | undefined | null = await dispatch(saveGameToService(props));

        if (!game) {
            return;
        }
        dispatch(setLocalSettings(game));
        backToGameListing();
        showSuccessToast("Saved Changes");
    };

    const createCopy = async () => {
        if (copiedGameName.length < 3) {
            showErrorToast("Copied Game Name must contain 3 or more characters.");
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const game: Game | undefined | null = await dispatch(
            createCopyOfGame({originalGameId: props.id, newName: copiedGameName})
        );
        if (!game) return;
        dispatch(setLocalSettings(game));
        window.scrollTo(0, 0);
        const path = `${routeNames.gameSettingsEdit.path}/${game.id}`;
        history.replace({pathname: path, search: location.search});
        toggle();
    };

    const viewCompleteGame = () => {
        const path = `${routeNames.completeGameGameMaster.path}/${props.id}`;
        history.replace(path);
    };

    const isCopyRoute = () => {
        const currentLocation = window.location.pathname;
        return currentLocation.includes(routeNames.gameSettingsCopy.path) || isInGame();
    };

    const isInGame = () => {
        switch (props.state.step) {
            case GameStep.BetweenRounds:
            case GameStep.InRound:
                return true;
            case GameStep.Complete:
            case GameStep.PendingStart:
                return false;
        }
    };

    const areCompaniesValid = () => {
        const companyError: string[] = [];
        for (const company of props.companies) {
            companyError.push(...validateCompany(company, true, props.companies).messages);
        }
        return companyError.length === 0;
    };

    return (
        <React.Fragment>
            <div className="container fadeIn">
                <div className="pt-4" />
                <GeneralSettings {...props} />
                <div className="pt-4 pb-4" />
                <CompaniesSettings {...props} />
                {areCompaniesValid() ? (
                    <React.Fragment>
                        <div className="pt-4 pb-4" />
                        <CustomerSettings {...props} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="pt-4 pb-4" />
                        <FormWrapper>
                            <FormSection headerName={"Game Setup: Customers"}>
                                <div className="row ml-0 mr-0 fadeIn">
                                    <div className="col">
                                        <h5 className="text-center p-3">
                                            Please ensure all companies are valid before continuing.
                                        </h5>
                                    </div>
                                </div>
                            </FormSection>
                        </FormWrapper>
                    </React.Fragment>
                )}

                <div className="pt-4 pb-4" />
                {props.id > 0 && <GameState {...props} />}
                <div className="pt-4 pb-4" />
                {props.id > 0 && props.state.step === GameStep.PendingStart && (
                    <AccessCodeList {...props} />
                )}
                <div className="pb-4" />
                <FormActionContainer>
                    <div className="col mt-3">
                        {props.state.step === GameStep.PendingStart && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Save and Start"}
                                onClick={saveAndStart}
                                colour={ButtonColourOptions.Orange}
                            />
                        )}
                        {props.state.step === GameStep.PendingStart && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Save Changes"}
                                onClick={saveChanges}
                                colour={ButtonColourOptions.Orange}
                            />
                        )}
                        {props.state.step === GameStep.PendingStart && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Save And Back To Listing"}
                                onClick={saveAndBackToListing}
                                colour={ButtonColourOptions.Orange}
                            />
                        )}
                        {props.state.step === GameStep.BetweenRounds && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"View Game"}
                                onClick={viewCurrentGame}
                                colour={ButtonColourOptions.LightBlue}
                            />
                        )}
                        {props.state.step === GameStep.InRound && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"View Game"}
                                onClick={viewCurrentGame}
                                colour={ButtonColourOptions.LightBlue}
                            />
                        )}
                        {props.state.step === GameStep.Complete && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"View Completed Game"}
                                onClick={viewCompleteGame}
                                colour={ButtonColourOptions.LightBlue}
                            />
                        )}
                        {props.id > 0 && !isCopyRoute() && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Create Game From These Settings"}
                                onClick={toggle}
                                colour={ButtonColourOptions.LightBlue}
                            />
                        )}
                        <VIButton
                            roundedCorner
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={backToGameListing}
                            colour={ButtonColourOptions.DarkBlue}
                        />
                    </div>
                </FormActionContainer>
            </div>
            <div className="pb-4" />
            <Modal
                isShown={isShown}
                hide={toggle}
                modalSize={ModalSize.extra_small}
                headerText={"Copied Game Name"}
            >
                <React.Fragment>
                    <FormRow rowName={"Name of the copied game"} formRowClassName={"mr-3"}>
                        <input
                            className="input-fields"
                            value={copiedGameName}
                            onChange={(e) => {
                                setCopiedGameName(e.target.value);
                            }}
                        />
                    </FormRow>
                    <FormActionContainer withColumn rowClassName={"mt-3"}>
                        <VIButton
                            roundedCorner
                            size={ButtonSize.Large}
                            innerValue={"Create Game Copy"}
                            onClick={createCopy}
                            colour={ButtonColourOptions.Orange}
                        />
                        <VIButton
                            roundedCorner
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                        />
                    </FormActionContainer>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default EditGameSettingsForm;
