import React from "react";
import {CompanyPerRound, RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import FormRow from "../../../../../../Form/FormRow";

const StartingRoundCompanyInformation = (props: RunningGameStatus) => {
    const companyPerRound = getCompanyRound(props);
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`${props.companies[0].name} Starting Round Details (Round ${props.companies[0].startingRound})`}
                headerType={"h5"}
                showRow
            />
            <FormRow
                rowName={"Sourcing Limit:"}
                formRowClassName={"ml-3 mt-3"}
                columnClassName="pr-3"
            >
                {companyPerRound && (
                    <p className="dark-blue-text overflow-wrap">{companyPerRound.sourcingLimit}</p>
                )}
            </FormRow>
            <FormRow
                rowName={"Cost of goods:"}
                formRowClassName={"ml-3 mt-3"}
                columnClassName="pr-3"
            >
                {companyPerRound && (
                    <p className="dark-blue-text overflow-wrap">{companyPerRound.costPerPack}</p>
                )}
            </FormRow>
        </React.Fragment>
    );
};

export default StartingRoundCompanyInformation;

function getCompanyRound(gameStatus: RunningGameStatus): CompanyPerRound | undefined {
    const startingRound = gameStatus.companies[0].startingRound;
    const index = gameStatus.companies[0].rounds.findIndex(
        (item: CompanyPerRound) => item.round === startingRound
    );

    if (index < 0) return;

    return gameStatus.companies[0].rounds[index];
}
