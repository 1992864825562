import React from "react";
import {GamePerRound, GameStep} from "../../../../../../api";
import {useGameState} from "../../../../../Hooks/useGameState";
import {getMinutesPerRoundError} from "../GeneralSettings";
import {useDispatch} from "react-redux";
import {updateGamePerRoundSetting} from "../../../../../../store/gameSettings/actions/GameSettingsActions";
import {clamp} from "../../../../../../utils/mathUtils";

const GamePerRoundSettingsItem = (props: GamePerRound) => {
    const gameState = useGameState();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <tr className="vi-table-row">
                <td align="left" className="vi-table-row-item-item mw-20 p-3">
                    {props.round}
                </td>
                <td align="left" className="vi-table-row-item-item mw-20 p-3">
                    <React.Fragment>
                        <input
                            className="input-fields"
                            value={props.roundTimeMins}
                            onChange={(event) => {
                                const {value} = event.target;
                                const timePerRound = clamp(+value, 1, 60);
                                dispatch(
                                    updateGamePerRoundSetting({
                                        ...props,
                                        roundTimeMins: timePerRound
                                    })
                                );
                            }}
                            placeholder={"Enter time per round...."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"number"}
                            min={0}
                            max={60}
                        />
                        {getMinutesPerRoundError(props.roundTimeMins, 60)}
                    </React.Fragment>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default GamePerRoundSettingsItem;
