import {Dispatch} from "redux";
import {
    RUNNING_GAME_STATUS_STORE,
    RunningGameStatusDispatchTypes
} from "./RunningGameStatusActionsTypes";
import {getDataFromServiceWithRedux} from "../../../modules/storeFetchWrappers/store/helpers/utils/StoreFetchWrappers";
import TenderGameApiModel from "../../apiModel/TenderGameApiModel";
import {getAdminHeader, getUserHeader, statusCodeCallback} from "../../apiModel/BaseApi";

export const nullifyRunningGameStatusStore = () => {
    return async (dispatch: Dispatch<RunningGameStatusDispatchTypes>) => {
        dispatch({
            type: RUNNING_GAME_STATUS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const getMyGame = () => {
    return async (dispatch: Dispatch<RunningGameStatusDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                RUNNING_GAME_STATUS_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().getGameForPlayer(getUserHeader()),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: RUNNING_GAME_STATUS_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const getGameStatusById = (gameId: number) => {
    return async (dispatch: Dispatch<RunningGameStatusDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                RUNNING_GAME_STATUS_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().getGameState(gameId, getAdminHeader()),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: RUNNING_GAME_STATUS_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const getGameStatusByIdViaRedux = (gameId: number) => {
    return async (dispatch: Dispatch<RunningGameStatusDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                RUNNING_GAME_STATUS_STORE,
                dispatch,
                () => TenderGameApiModel.getTenderApi().getGameState(gameId, getAdminHeader()),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: RUNNING_GAME_STATUS_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
