import React, {useEffect} from "react";
import logo from "../../.././../images/VI_LogoStrapline_Blue-Gold_Primary.png";
import {useHistory} from "react-router-dom";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../Button/VIButton";
import {routeNames} from "../../../Navigation/routeNames";
import {useDispatch} from "react-redux";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";

const FourOhThree = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setNavigationItems([]));
        localStorage.removeItem("basic_auth_creds");
        localStorage.removeItem("auth_game_code");
    }, []);

    const login = () => {
        localStorage.removeItem("basic_auth_creds");
        localStorage.removeItem("auth_game_code");
        history.replace(routeNames.userLogin.path);
    };
    return (
        <React.Fragment>
            <div className="container fadeIn">
                <div className="pt-5" />
                <div className="d-grid min-vh-100">
                    <div className="row">
                        <div className="col-6">
                            <img src={logo} className="max-width-400px" alt="" />
                        </div>
                        <div className="col-6 text-center mt-5">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            <h3>You failed VALID-ation</h3>
                            <h6 className="mt-3">It is just a 403 Error!</h6>
                            <h6>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                You shouldn't be looking at the resource you were requesting. If you
                                are who you say you are, please log back in here!
                            </h6>
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Login"}
                                onClick={login}
                                colour={ButtonColourOptions.Orange}
                                className={"max-width-200px"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default FourOhThree;
