import React from "react";
import {CompanyPerRound, GameStep} from "../../../../../../api";
import {useDispatch} from "react-redux";
import {useGameState} from "../../../../../Hooks/useGameState";
import Table from "../../../../../Table/PulseTable";
import FormError from "../../../../../Form/FormError";
import {setPerRoundCompanySettings} from "../../../../../../store/gameSettings/actions/GameSettingsActions";
import {clamp} from "../../../../../../utils/mathUtils";

const CompanyPerRoundSettingsTable = (props: CompanyPerRoundSettingsTableProps) => {
    const dispatch = useDispatch();
    const gameState = useGameState();

    return (
        <React.Fragment>
            <Table
                items={toTableRow(props)}
                headers={{
                    roundNumber: "Round Number",
                    round: "Sourcing Limit"
                }}
                customRenderers={{
                    round: (item: TableRow) => {
                        return (
                            <React.Fragment>
                                <input
                                    className="input-fields"
                                    value={item.round.sourcingLimit}
                                    onChange={(event) => {
                                        const {value} = event.target;
                                        dispatch(
                                            setPerRoundCompanySettings({
                                                companyPerRound: {
                                                    ...item.round,
                                                    sourcingLimit: clamp(+value, 0, 10000)
                                                },
                                                roundSettingIndex: item.roundNumber - 1,
                                                companyName: props.companyName
                                            })
                                        );
                                    }}
                                    placeholder={"Enter sourcing limit..."}
                                    disabled={gameState !== GameStep.PendingStart}
                                    type={"number"}
                                />
                                {getSourcingLimitError(item.round.sourcingLimit)}
                            </React.Fragment>
                        );
                    }
                }}
            />
        </React.Fragment>
    );
};

export default CompanyPerRoundSettingsTable;

interface CompanyPerRoundSettingsTableProps {
    perRoundSettings: CompanyPerRound[];
    companyName: string;
    startingRound: number;
}

interface TableRow {
    roundNumber: number;
    round: CompanyPerRound;
}

function toTableRow(props: CompanyPerRoundSettingsTableProps): TableRow[] {
    const rows: TableRow[] = [];

    for (const round of props.perRoundSettings) {
        if (round.round < props.startingRound) continue;

        rows.push({
            roundNumber: round.round,
            round
        });
    }

    return rows;
}

function getSourcingLimitError(sourcingLimit: number) {
    if (sourcingLimit === 0) {
        return <FormError errorMessage={"Sourcing limit must be more than 0"} />;
    }
    if (sourcingLimit > 10000) {
        return <FormError errorMessage={"Sourcing limit must be more than 10000"} />;
    }
}
