import React, {useCallback, useEffect, useState} from "react";
import FormWrapper from "../../Form/FormWrapper";
import FormHeader from "../../Form/FormHeader";
import FormRow from "../../Form/FormRow";
import FormActionContainer from "../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../Button/VIButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";
import {showErrorToast} from "../../../utils/toastUtils";
import {getEncodedToken} from "../../../store/apiModel/BaseApi";
import {useDispatch} from "react-redux";
import {setBase64Code} from "../../../store/user/actions/UserActions";

const UserLogin = () => {
    const history = useHistory();
    const [accessCode, setAccessCode] = useState("");
    const dispatch = useDispatch();
    const goToGameMasterLogin = () => {
        history.push(routeNames.gameMasterLogin.path);
    };

    const checkKeyPress = useCallback(
        (e) => {
            const {keyCode} = e;

            if (keyCode === 13) {
                enterSystem();
            }
        },
        [accessCode]
    );

    useEffect(() => {
        document.addEventListener("keyup", checkKeyPress);

        return () => document.removeEventListener("keyup", checkKeyPress);
    }, [checkKeyPress]);

    const enterSystem = () => {
        goToGame(accessCode);
    };

    const goToGame = (code: string) => {
        if (code.length !== 10) {
            showErrorToast("Invalid Access Code");
            return;
        }

        const base64EncodedAuth = getEncodedToken(code, code);
        dispatch(setBase64Code(base64EncodedAuth));
        history.push({
            pathname: routeNames.currentGame.path,
            search: `?accessCode=${accessCode}`
        });
    };

    return (
        <React.Fragment>
            <div className="container min-vh-100 d-grid">
                <FormWrapper wrapperClassName={"min-width-350px fadeIn"}>
                    <FormHeader
                        headerName={"Enter Access Code"}
                        textAlign={"center"}
                        headerType={"h3"}
                        showRow
                    />
                    <FormRow
                        rowName={"Access Code"}
                        formRowClassName={"mt-3"}
                        rowClassName={"ml-3 mr-4 pr-2"}
                    >
                        <input
                            onChange={(event) => {
                                const {value} = event.target;
                                setAccessCode(value);
                            }}
                            className="input-fields"
                            type={"text"}
                            placeholder={"Enter access code here...."}
                            disabled={false}
                            value={accessCode}
                        />
                    </FormRow>
                    <FormActionContainer>
                        <div className="col text-center mt-3">
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Enter"}
                                onClick={enterSystem}
                                colour={ButtonColourOptions.Orange}
                            />
                        </div>
                    </FormActionContainer>
                    <div className="pt-2" />
                    <div className={`row ml-0 mr-0 vi-form-heading`}>
                        <div className="col text-center">
                            <h6
                                className="orange-hyperlink-text cursor-pointer"
                                onClick={goToGameMasterLogin}
                            >
                                Are you a game master? Login in here!
                            </h6>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        </React.Fragment>
    );
};

export default UserLogin;
