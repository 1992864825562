import React from "react";
import {Round} from "../../../../../../api";
import RoundTable from "./RoundTable";
import {RoundTableBase} from "./Helpers/roundTableHelpers";

const RoundTableWrapper = (props: RoundTableWrapperProps) => {
    return (
        <React.Fragment>
            <RoundTable
                bids={props.round.bids}
                showPenaltyColumn={props.showPenaltyColumn}
                showRankColumn={props.showRankColumn}
                showMaxProfitColumn={props.showMaxProfitColumn}
                showMaxRevenueColumn={props.showMaxRevenueColumn}
                showCompanyColumn={props.showCompanyColumn}
                showCustomerColumn={props.showCustomerColumn}
            />
        </React.Fragment>
    );
};

export default RoundTableWrapper;

interface RoundTableWrapperProps extends RoundTableBase {
    round: Round;
}
