import React from "react";

/** Helpers */

// helper to get an array containing the object values with
// the correct type infered.
// eslint-disable-next-line @typescript-eslint/ban-types
function objectValues<T extends {}>(obj: T) {
    return Object.keys(obj).map((objKey) => obj[objKey as keyof T]);
}

// eslint-disable-next-line @typescript-eslint/ban-types
function objectKeys<T extends {}>(obj: T) {
    return Object.keys(obj).map((objKey) => objKey as keyof T);
}

type PrimitiveType = string | symbol | number | boolean;

// Type guard for the primitive types which will support printing
// out of the box
function isPrimitive(value: any): value is PrimitiveType {
    return (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean" ||
        typeof value === "symbol"
    );
}

/** Component */
// eslint-disable-next-line @typescript-eslint/ban-types
type TableHeaders<T extends {}> = Record<keyof T, string>;

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomRenderers<T extends {}> = Partial<Record<keyof T, (it: T) => React.ReactNode>>;

// eslint-disable-next-line @typescript-eslint/ban-types
interface TableProps<T extends {}> {
    items: T[];
    headers: TableHeaders<T>;
    customRenderers?: CustomRenderers<T>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default function Table<T extends {}>(props: TableProps<T>) {
    function renderRow(item: T) {
        return (
            <tr className="vi-table-row">
                {objectKeys(item).map((itemProperty, index) => {
                    const customRenderer = props.customRenderers?.[itemProperty];

                    if (customRenderer) {
                        return (
                            <td key={index} align="left" className="vi-table-row-item-item p-3">
                                {customRenderer(item)}
                            </td>
                        );
                    }

                    return (
                        <td align="left" className="vi-table-row-item-item p-3" key={index}>
                            {isPrimitive(item[itemProperty]) ? item[itemProperty] : ""}
                        </td>
                    );
                })}
            </tr>
        );
    }

    return (
        <div className="pb-5 mt-3 ml-3 mr-3">
            <div className="d-block table-overflow-x vi-table-wrapper">
                <table className="vi-table fadeIn">
                    <thead>
                        <tr className="vi-table-header">
                            {objectValues(props.headers).map((headerValue, index) => (
                                <th align="left" className="vi-table-header-item p-3" key={index}>
                                    {headerValue}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{props.items.map(renderRow)}</tbody>
                </table>
            </div>
        </div>
    );
}
