import {combineReducers} from "redux";
import gameSettingsReducer from "./gameSettings/reducer/GameSettingsReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import gameListReducer from "./gameList/reducer/GameListReducer";
import gameMasterListReducer from "./gameMasterList/reducer/GameMasterListReducer";
import gameMasterReducer from "./gameMaster/reducer/GameMasterReducer";
import runningGameStatusReducer from "./runningGameStatus/reducer/RunningGameStatusReducer";
import gameReducer from "./game/reducer/GameReducer";
import bidReducer from "./bid/reducer/BidReducer";
import userReducer from "./user/reducer/UserReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    gameSettings: gameSettingsReducer,
    gameList: gameListReducer,
    gameMasterList: gameMasterListReducer,
    gameMaster: gameMasterReducer,
    runningGameStatus: runningGameStatusReducer,
    game: gameReducer,
    bid: bidReducer,
    user: userReducer
});

export default RootReducer;
