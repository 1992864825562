import React from "react";
import {RunningGameStatus} from "../../../../../../api";
import FormWrapper from "../../../../../Form/FormWrapper";
import GameSummary from "../../../GameMaster/CompleteGame/Components/GameSummary";
import {getRoundForCompany} from "../InRound/InRoundScreen";
import FormHeader from "../../../../../Form/FormHeader";
import RoundTableWrapper from "../../../Shared/Components/RoundTable/RoundTableWrapper";
import FormRow from "../../../../../Form/FormRow";

const PlayerCompleteGame = (props: RunningGameStatus) => {
    const currentRound = getRoundForCompany(props);
    return (
        <React.Fragment>
            <FormWrapper>
                <GameSummary {...props} />
                <FormRow rowName={"Game Information"} formRowClassName={"ml-3 pt-1 pr-3"}>
                    <p className="dark-blue-text">
                        The game has ended! Turn your attention to the game master for futher
                        instructions!
                    </p>
                </FormRow>
            </FormWrapper>
            <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                <React.Fragment>
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Round ${props.round} Summary`}
                        headerType={"h2"}
                        showRow
                    />
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Bid Summary for Round ${props.round}`}
                        headerType={"h2"}
                        showRow
                    />
                    {currentRound && (
                        <RoundTableWrapper
                            round={currentRound}
                            showRankColumn={false}
                            showPenaltyColumn={false}
                            showMaxProfitColumn={false}
                            showMaxRevenueColumn={false}
                            showCompanyColumn={false}
                            showCustomerColumn={true}
                        />
                    )}
                </React.Fragment>
            </FormWrapper>
        </React.Fragment>
    );
};

export default PlayerCompleteGame;
