import React from "react";
import {Game, GameStep} from "../../../../../api";
import FormWrapper from "../../../../Form/FormWrapper";
import {useDispatch} from "react-redux";
import FormRow from "../../../../Form/FormRow";
import {
    setLocalSettings,
    updateNumberOfRounds
} from "../../../../../store/gameSettings/actions/GameSettingsActions";
import FormSection from "../../../../Form/FormSection";
import FormError from "../../../../Form/FormError";
import {useGameState} from "../../../../Hooks/useGameState";
import GamePerRoundSettings from "./GamePerRound/GamePerRoundSettings";
import {clamp} from "../../../../../utils/mathUtils";
import CharacterLimitWarning from "../../../../Form/CharacterLimitWarning";

const GeneralSettings = (props: Game) => {
    const dispatch = useDispatch();
    const gameState = useGameState();
    return (
        <FormWrapper>
            <FormSection headerName={"Game Setup: General"}>
                <FormRow
                    rowName={"Name of the game:"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                >
                    <React.Fragment>
                        <input
                            className="input-fields"
                            value={props.name}
                            onChange={(event) => {
                                const {value} = event.target;
                                dispatch(
                                    setLocalSettings({
                                        ...props,
                                        name: value
                                    })
                                );
                            }}
                            placeholder={"Enter name of game...."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"text"}
                        />
                        <CharacterLimitWarning characterLimit={256} textValue={props.name} />
                        {props.name.length < 3 && (
                            <FormError
                                errorMessage={"Game name must be more than 3 characters long!"}
                            />
                        )}
                    </React.Fragment>
                </FormRow>
                <FormRow
                    rowName={"Game Description:"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                >
                    <React.Fragment>
                        <textarea
                            className="input-fields"
                            style={{minHeight: `150px`}}
                            value={props.description}
                            onChange={(event) => {
                                const {value} = event.target;
                                // if (value.length > 512) return;
                                dispatch(
                                    setLocalSettings({
                                        ...props,
                                        description: value
                                    })
                                );
                            }}
                            placeholder={"Enter game description...."}
                            disabled={gameState !== GameStep.PendingStart}
                        />
                        <CharacterLimitWarning
                            characterLimit={512}
                            textValue={props.description || ""}
                        />
                        {!props.description && (
                            <FormError errorMessage={"Game description is required!"} />
                        )}
                    </React.Fragment>
                </FormRow>
                <FormRow
                    rowName={"Number of rounds:"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                    showTooltip
                    tooltipText={"Maximum number of rounds for the game"}
                >
                    <React.Fragment>
                        <input
                            className="input-fields"
                            value={props.numberRounds}
                            onChange={(event) => {
                                const {value} = event.target;
                                const minFloorValue = value.length > 0 ? +value : 1;
                                const numberOfRounds = clamp(minFloorValue, 1, 6);
                                // const value =
                                //     event.target.value.length > 0 ? +event.target.value : 1;
                                dispatch(
                                    updateNumberOfRounds({
                                        ...props,
                                        numberRounds: numberOfRounds
                                    })
                                );
                            }}
                            placeholder={"Enter number of rounds...."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"number"}
                            min={1}
                            max={9}
                        />
                        {getNumberRoundError(props.numberRounds, 6)}
                    </React.Fragment>
                </FormRow>
                <FormRow
                    rowName={"Time per round (Minutes):"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                >
                    <React.Fragment>
                        <input
                            className="input-fields"
                            value={props.timePerRoundMins}
                            onChange={(event) => {
                                const {value} = event.target;
                                const timePerRound = clamp(+value, 1, 60);
                                dispatch(
                                    setLocalSettings({
                                        ...props,
                                        timePerRoundMins: timePerRound
                                    })
                                );
                            }}
                            placeholder={"Enter time per round...."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"number"}
                            min={0}
                            max={60}
                        />
                        {getMinutesPerRoundError(props.timePerRoundMins, 60)}
                    </React.Fragment>
                </FormRow>
                <GamePerRoundSettings {...props} />
                {/*<FormRow*/}
                {/*    rowName={"Clinical unit name:"}*/}
                {/*    formRowClassName={"mt-3"}*/}
                {/*    rowClassName={"ml-0 mr-3"}*/}
                {/*    showTooltip*/}
                {/*    tooltipText={"The name of the clinical unit, for example 'Antibiotic Course'"}*/}
                {/*>*/}
                {/*    <React.Fragment>*/}
                {/*        <input*/}
                {/*            className="input-fields"*/}
                {/*            value={props.clinicalUnitName}*/}
                {/*            onChange={(event) => {*/}
                {/*                dispatch(*/}
                {/*                    setLocalSettings({*/}
                {/*                        ...props,*/}
                {/*                        clinicalUnitName: event.target.value*/}
                {/*                    })*/}
                {/*                );*/}
                {/*            }}*/}
                {/*            placeholder={"Enter clinical unit name...."}*/}
                {/*            disabled={gameState !== GameStep.PendingStart}*/}
                {/*            type={"text"}*/}
                {/*        />*/}
                {/*        {props.clinicalUnitName.length < 3 && (*/}
                {/*            <FormError*/}
                {/*                errorMessage={*/}
                {/*                    "Clinical unit name must be more than 3 characters long!"*/}
                {/*                }*/}
                {/*            />*/}
                {/*        )}*/}
                {/*    </React.Fragment>*/}
                {/*</FormRow>*/}
            </FormSection>
        </FormWrapper>
    );
};

export default GeneralSettings;

function getNumberRoundError(numberRounds: number, maxNumberOfRounds: number) {
    if (numberRounds === 0) {
        return <FormError errorMessage={"Game rounds must be more than 0"} />;
    }
    if (numberRounds > maxNumberOfRounds) {
        return (
            <FormError errorMessage={`Game rounds cannot exceed more than ${maxNumberOfRounds}`} />
        );
    }
}

export function getMinutesPerRoundError(timePerRound: number | undefined, maxMinuteAmount: number) {
    if (!timePerRound) {
        return <FormError errorMessage={"Duration of a round cannot be blank"} />;
    }
    if (timePerRound === 0) {
        return <FormError errorMessage={"Duration of a round must be more than 0 minutes"} />;
    }
    if (timePerRound > maxMinuteAmount) {
        return (
            <FormError
                errorMessage={`Duration of a round cannot be more than ${maxMinuteAmount} minutes`}
            />
        );
    }
}
