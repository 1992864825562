import React from "react";
import {GameStatusCustomer, RunningGameStatus} from "../../../../../../api";
import FormWrapper from "../../../../../Form/FormWrapper";
import FormHeader from "../../../../../Form/FormHeader";

const CustomerNextRoundInfo = (props: CustomerNextRoundInfoProps) => {
    const getHeaderName = () => {
        if (props.showNextRoundText) {
            return `Customer Details`;
        }

        return "Customer Details";
    };
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={getHeaderName()}
                headerType={"h4"}
                showRow
            />
            {props.gameStatus.customers.map((customer: GameStatusCustomer, index: number) => {
                return (
                    <FormWrapper key={index} wrapperClassName={"m-3"}>
                        <FormHeader
                            rowClassName={"mt-3"}
                            headerName={customer.name}
                            headerType={"h5"}
                            showRow
                        />
                        <div className="ml-3 mr-3">
                            <h6 className="mb-0 mt-2 font-weight-bold">Description:</h6>
                            <p className="dark-blue-text">{customer.description}</p>
                        </div>
                    </FormWrapper>
                );
            })}
        </React.Fragment>
    );
};

export default CustomerNextRoundInfo;

interface CustomerNextRoundInfoProps {
    showNextRoundText: boolean;
    gameStatus: RunningGameStatus;
}
