import React from "react";
import {GameStep, RunningGameStatus} from "../../../../../../api";
import FormHeader from "../../../../../Form/FormHeader";
import FormRow from "../../../../../Form/FormRow";
import RoundIndicator from "../../../Shared/Components/RoundIndicator/RoundIndicator";
import {capitalizeFirstLetter, getUiFriendlyText} from "../../../../../../utils/textUtils";
import SharedRoundDetails from "../SharedRoundDetails";
import RoundTime from "../../../Shared/Components/RoundTime/RoundTime";

const SharedGameInformation = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Game Information`}
                headerType={"h2"}
                showRow
            />
            <SharedRoundDetails {...props} />
            {hasCompanyStarted(props) && (
                <FormRow
                    rowName={`${props.companies[0].name} Starting Round:`}
                    formRowClassName={"ml-3 pr-3"}
                >
                    <p className="dark-blue-text">{props.companies[0].startingRound}</p>
                </FormRow>
            )}
            <RoundIndicator {...props} />
            <RoundTime {...props} />
            <FormRow rowName={"Number of customers:"} formRowClassName={"ml-3"}>
                <p className="dark-blue-text">{props.customers.length}</p>
            </FormRow>
            <FormRow rowName={"Game State:"} formRowClassName={"ml-3"}>
                <p className="dark-blue-text">{getUiFriendlyText(props.step)}</p>
            </FormRow>
        </React.Fragment>
    );
};

export default SharedGameInformation;

function hasCompanyStarted(gameStatus: RunningGameStatus) {
    switch (capitalizeFirstLetter(gameStatus.step)) {
        case GameStep.PendingStart:
            return gameStatus.round + 1 < gameStatus.companies[0].startingRound;
        default:
            return gameStatus.round < gameStatus.companies[0].startingRound;
    }
}
