import React from "react";
import {RunningGameStatus} from "../../../../../api";
import FormWrapper from "../../../../Form/FormWrapper";
import GameSummary from "./Components/GameSummary";
import DataTables from "./Components/DataTables/DataTables";
import RankingTables from "./Components/RankingTables/RankingTables";
import PostGameGraphs from "./Components/Statistics/PostGameGraphs";

const CompleteGame = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormWrapper>
                <GameSummary {...props} />
            </FormWrapper>
            <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                <PostGameGraphs {...props} />
            </FormWrapper>
            <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                <RankingTables {...props} />
            </FormWrapper>
            <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                <DataTables {...props} />
            </FormWrapper>
        </React.Fragment>
    );
};

export default CompleteGame;
