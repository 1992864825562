import React from "react";
import {ComponentChildrenProps} from "../../utils/componentUtils";

const FormWrapper = (props: FormWrapperProps) => (
    <div className={`vi-form-wrapper bg-white ${props.wrapperClassName}`}>{props.children}</div>
);

export default FormWrapper;

interface FormWrapperProps extends ComponentChildrenProps {
    wrapperClassName?: string;
}
