import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {GameStep} from "../../api";
import {capitalizeFirstLetter} from "../../utils/textUtils";

export function useGameState() {
    const game = useSelector((state: RootStore) => state.gameSettings.data);

    if (!game) return GameStep.PendingStart;

    return capitalizeFirstLetter(game.state.step);
}
