import React from "react";
import {RunningGameStatus} from "../../../../../../api";
import FormHeader from "../../../../../Form/FormHeader";
import FormWrapper from "../../../../../Form/FormWrapper";
import FormRow from "../../../../../Form/FormRow";
import RoundTableWrapper from "../../../Shared/Components/RoundTable/RoundTableWrapper";
import ClinicalUnitRequirementTable from "../../../Shared/Components/CustomerNextRoundInfo/Table/ClinicalUnitRequirementTable";
import CompanyRoundInfo from "../../../Shared/Components/CompanyRoundInfo/CompanyRoundInfo";
import {getRound} from "../InRound/InRoundScreen";
import SharedGameInformation from "../Shared/SharedGameInformation";

const BetweenRoundsScreen = (props: RunningGameStatus) => {
    const currentRound = getRound(props);
    return (
        <React.Fragment>
            <FormWrapper>
                <React.Fragment>
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Next Round Information (Round ${props.round + 1})`}
                        headerType={"h2"}
                        showRow
                    />
                    <CompanyRoundInfo runningGameStatus={props} isCurrentRound={false} />
                    <ClinicalUnitRequirementTable {...props} />
                    {/*<FormRow rowName={"Summary:"} formRowClassName={"ml-3"}>*/}
                    {/*    <p className="dark-blue-text">*/}
                    {/*        Here are the bids your company made in round {props.round}.*/}
                    {/*    </p>*/}
                    {/*</FormRow>*/}
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Round ${props.round} Summary`}
                        headerType={"h2"}
                        showRow
                    />
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Bid Summary for Round ${props.round}`}
                        headerType={"h2"}
                        showRow
                    />
                    {currentRound && (
                        <RoundTableWrapper
                            round={currentRound}
                            showRankColumn={false}
                            showPenaltyColumn={false}
                            showMaxProfitColumn={false}
                            showMaxRevenueColumn={false}
                            showCompanyColumn={false}
                            showCustomerColumn={true}
                        />
                    )}
                    {/*<FormHeader*/}
                    {/*    rowClassName={"mt-3"}*/}
                    {/*    headerName={`Stats for Round ${props.round}`}*/}
                    {/*    headerType={"h2"}*/}
                    {/*    showRow*/}
                    {/*/>*/}
                    {/*<MarketShareWrapper*/}
                    {/*    gameStep={props.step}*/}
                    {/*    round={props.round}*/}
                    {/*    companyStats={props.companyRoundStats}*/}
                    {/*/>*/}
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Post Round Instructions`}
                        headerType={"h2"}
                        showRow
                    />
                    <FormRow rowName={""} formRowClassName={"ml-3 pt-3 pr-3"}>
                        <p className="dark-blue-text">
                            Above is the summary for your bids during the last round. Please wait
                            for the Game Master to start the next round.
                        </p>
                    </FormRow>
                    <SharedGameInformation {...props} />
                </React.Fragment>
            </FormWrapper>
        </React.Fragment>
    );
};

export default BetweenRoundsScreen;
