import React from "react";
import {RunningGameStatus} from "../../../../../../api";
import FormHeader from "../../../../../Form/FormHeader";
import RoundIndicator from "../../../Shared/Components/RoundIndicator/RoundIndicator";
import FormRow from "../../../../../Form/FormRow";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import SharedRoundDetails from "../../../Player/Components/SharedRoundDetails";

const GameSummary = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormHeader rowClassName={"mt-3"} headerName={`Summary`} headerType={"h2"} showRow />
            <SharedRoundDetails {...props} />
            <RoundIndicator {...props} />
            <FormRow rowName={"Game State:"} formRowClassName={"ml-3"}>
                <p className="dark-blue-text">{getUiFriendlyText(props.step)}</p>
            </FormRow>
        </React.Fragment>
    );
};

export default GameSummary;
