import React, {useEffect, useState} from "react";
import {GameStatusCustomer, Round, RunningGameStatus} from "../../../../../../api";
import FormHeader from "../../../../../Form/FormHeader";
import FormWrapper from "../../../../../Form/FormWrapper";
import FormRow from "../../../../../Form/FormRow";
import CustomerBids from "./CustomerBids";
import SharedRoundDetails from "../SharedRoundDetails";
import {showSuccessToast} from "../../../../../../utils/toastUtils";
import {getYearForRound} from "../../Helpers/playerGameScreenHelpers";
import Timer from "../../../../../Timer/Timer";
import {useDispatch} from "react-redux";
import {getMyGame} from "../../../../../../store/runningGameStatus/actions/RunningGameStatusActions";
import RoundIndicator from "../../../Shared/Components/RoundIndicator/RoundIndicator";
import RoundTableWrapper from "../../../Shared/Components/RoundTable/RoundTableWrapper";
import {getTimeForNextRound} from "../../../Shared/Components/RoundTime/RoundTime";
import CompanyRoundInfo from "../../../Shared/Components/CompanyRoundInfo/CompanyRoundInfo";
import {getValueOfItem} from "../../../GameMaster/CompleteGame/Components/DataTables/DataTables";
import {bankersRound} from "../../../../../../utils/mathUtils";

const InRoundScreen = (props: RunningGameStatus) => {
    const [successfulBids, setSuccessfulBids] = useState<number>(0);
    const dispatch = useDispatch();
    const previousRound = getPreviousRound(props);

    useEffect(() => {
        if (successfulBids === props.customers.length) {
            showSuccessToast("All bids have been made for this round! Well done!");
            return;
        }
    }, [successfulBids]);

    const currentRound = getRound(props);

    return (
        <React.Fragment>
            <FormWrapper>
                <React.Fragment>
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Round ${props.round}`}
                        headerType={"h2"}
                        showRow
                    />
                    <SharedRoundDetails {...props} />
                    <RoundIndicator {...props} />
                    <FormRow rowName={"Timer:"} formRowClassName={"ml-3"}>
                        {currentRound && (
                            <Timer
                                roundStart={currentRound.roundStartDate || 0}
                                roundDuration={getTimeForNextRound(props)}
                                //Poll the service, the game would have ended. Get info for the game screen
                                onTimerFinishedCallback={() => dispatch(getMyGame())}
                                showToasts={true}
                            />
                        )}
                    </FormRow>
                    <FormRow rowName={"Average Bid:"} formRowClassName={"ml-3 mt-3"}>
                        <p className="dark-blue-text overflow-wrap">
                            {getAverageBidForCurrentRound(props)}
                        </p>
                    </FormRow>
                    <CompanyRoundInfo runningGameStatus={props} isCurrentRound={true} />
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Bids`}
                        headerType={"h2"}
                        showRow
                    />
                    {sortAlphabetically(props.customers).map(
                        (customer: GameStatusCustomer, index: number) => {
                            return (
                                <CustomerBids
                                    key={index}
                                    customerName={customer.name}
                                    gameId={props.id}
                                    onBidSuccess={() => setSuccessfulBids(successfulBids + 1)}
                                    gameStatus={props}
                                />
                            );
                        }
                    )}
                    {successfulBids === props.customers.length && (
                        <React.Fragment>
                            <FormHeader
                                rowClassName={"mt-3"}
                                headerName={`Post Round Information`}
                                headerType={"h2"}
                                showRow
                            />
                            <FormRow rowName={""} formRowClassName={"ml-3 pt-3 pr-3"}>
                                <p className="dark-blue-text">
                                    You have successfully made all bids for{" "}
                                    {getYearForRound(props.round)}! Please wait for the other
                                    companies to make their bids or for the round to end. Any
                                    questions, please ask the Game Master!
                                </p>
                            </FormRow>
                        </React.Fragment>
                    )}
                </React.Fragment>
            </FormWrapper>
            {previousRound && (
                <FormWrapper wrapperClassName={"mt-3"}>
                    <React.Fragment>
                        <React.Fragment>
                            <FormHeader
                                rowClassName={"mt-3"}
                                headerName={`Bid Summary for Previous Round (Round ${previousRound.number})`}
                                headerType={"h2"}
                                showRow
                            />
                            <RoundTableWrapper
                                round={previousRound}
                                showRankColumn={false}
                                showPenaltyColumn={false}
                                showMaxProfitColumn={false}
                                showMaxRevenueColumn={false}
                                showCompanyColumn={false}
                                showCustomerColumn={true}
                            />
                        </React.Fragment>
                    </React.Fragment>
                </FormWrapper>
            )}
        </React.Fragment>
    );
};

export default InRoundScreen;

export function getRound(props: RunningGameStatus): Round | undefined {
    const index = props.rounds.findIndex((item: Round) => item.number === props.round);

    if (index < 0) {
        return;
    }

    return props.rounds[index];
}

export function getPreviousRound(props: RunningGameStatus): Round | undefined {
    const index = props.rounds.findIndex((item: Round) => item.number === props.round - 1);

    if (index < 0) {
        return;
    }

    return props.rounds[index];
}

export function getAverageBidForCurrentRound(props: RunningGameStatus): number {
    const previousRound = getPreviousRound(props);

    if (!previousRound) return 100;

    return bankersRound(getValueOfItem(previousRound.averageBid));
}

export function getRoundForCompany(props: RunningGameStatus): Round | undefined {
    const index = props.rounds.findIndex((item: Round) => item.number === props.round);

    if (index < 0) {
        return;
    }

    return {
        ...props.rounds[index],
        bids: props.rounds[index].bids.filter(
            (item) => item.companyName === props.companies[0].name
        )
    };
}

function sortAlphabetically<T extends {name: string}>(items: T[]): T[] {
    return items.sort((a, b) => a.name.localeCompare(b.name));
}
