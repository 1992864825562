import React, {useEffect, useState} from "react";
import FormRow from "../../../../../Form/FormRow";
import {Bid, NewBid, RunningGameStatus} from "../../../../../../api";
import {ButtonColourOptions, ButtonSize} from "../../../../../Button/VIButton";
import {showErrorToast, showSuccessToast} from "../../../../../../utils/toastUtils";
import TenderGameApiModel from "../../../../../../store/apiModel/TenderGameApiModel";
import {getUserHeader} from "../../../../../../store/apiModel/BaseApi";
import {getRound} from "./InRoundScreen";
import {useModal} from "../../../../../../modules/modal/hooks/useModal";
import Modal from "../../../../../../modules/modal/components/Modal";
import {ModalSize} from "../../../../../../modules/modal/helpers/modalHelpers";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import ActionConfirmation from "../../../../../Confirmation/ActionConfirmation";

const CustomerBids = (props: CustomerBidsProps) => {
    const {isShown, toggle} = useModal();
    const runningGame = useSelector((state: RootStore) => state.runningGameStatus.data);
    const [currentBid, setCurrentBid] = useState<NewBid>({
        customerName: props.customerName,
        bid: 0
    });

    const customerInfo = getCustomerInformationForRound(runningGame, props.customerName);

    const [successfulBid, setSuccessfulBid] = useState<boolean>(false);

    const onBidConfirmed = async () => {
        const success = await placeNewBid(props.gameId, currentBid);
        if (!success) return;

        setSuccessfulBid(true);
    };

    useEffect(() => {
        checkIfUserHasMadeBid();
    }, [props]);

    const checkIfUserHasMadeBid = () => {
        const currentRound = getRound(props.gameStatus);
        if (!currentRound) return;
        const index = currentRound.bids.findIndex(
            (item: Bid) => item.customerName === props.customerName
        );

        if (index < 0) return;

        setSuccessfulBid(true);
        setCurrentBid({
            ...currentBid,
            bid: currentRound.bids[index].bid
        });
    };

    return (
        <React.Fragment>
            <FormRow
                rowName={props.customerName}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={"Show Customer Information"}
                onTooltipClick={toggle}
            >
                <div className="row ml-0 mr-0">
                    <div className={`d-inline-block`}>
                        {!successfulBid ? (
                            <input
                                className="input-fields max-width-200px"
                                value={currentBid.bid}
                                onChange={(event) => {
                                    setCurrentBid({...currentBid, bid: +event.target.value});
                                }}
                                placeholder={`Enter bid for ${props.customerName}....`}
                                type={"number"}
                                disabled={successfulBid}
                            />
                        ) : (
                            <p className="dark-blue-text">{currentBid.bid}</p>
                        )}
                    </div>
                    {!successfulBid && (
                        <div className="d-inline-block ml-2">
                            <div className="pt-2">
                                <ActionConfirmation
                                    roundedCorner
                                    size={ButtonSize.ExtraLarge}
                                    innerValue={"Submit Bid"}
                                    colour={ButtonColourOptions.Orange}
                                    confirmationText={
                                        "Are you sure you want to submit this bid? Once submitted, you will not be able to re-enter a bid for this round."
                                    }
                                    headerText={"Submit Bid"}
                                    onConfirm={onBidConfirmed}
                                    showCancelButton={true}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </FormRow>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={`${props.customerName} information`}
                modalSize={ModalSize.extra_small}
            >
                <React.Fragment>
                    <h5 className="mb-0">Description</h5>
                    <p>{customerInfo.customerDescription}</p>
                    <h5 className="mb-0">
                        Volume Requirement For Round {customerInfo.roundNumber}
                    </h5>
                    <p>{customerInfo.volumeRequirement}</p>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default CustomerBids;

interface CustomerBidsProps {
    gameId: number;
    customerName: string;
    onBidSuccess: () => void;
    gameStatus: RunningGameStatus;
}

async function placeNewBid(gameId: number, bid: NewBid): Promise<boolean> {
    try {
        if (bid.bid > 100) {
            showErrorToast("Validation failure: bid: Bid must not be greater than 100");
            return false;
        }
        if (bid.bid < 0) {
            showErrorToast("Validation failure: bid: Bid must not be negative");
            return false;
        }
        const request = await TenderGameApiModel.getTenderApi().sendBid(
            gameId,
            bid,
            getUserHeader()
        );

        if (request.status === 200 || request.status === 204) {
            showSuccessToast(`Bid for ${bid.customerName} was successful`);
            return true;
        }
        return false;
    } catch (e: any) {
        const error = e.response.data ? `: ${e.response.data.message}` : "";
        showErrorToast(`Could not place bid for ${bid.customerName} ${error}`);
        return false;
    }
}

function getCustomerInformationForRound(
    game: RunningGameStatus | undefined | null,
    customerName: string
): CustomerRoundInformation {
    if (!game) {
        return {
            volumeRequirement: 0,
            customerDescription: "",
            roundNumber: -1
        };
    }

    const {round, customers} = game;

    const customer = customers.filter((item) => item.name === customerName)[0];
    const index = customer.rounds.findIndex((item) => item.round === round);

    if (index < 0) {
        return {
            volumeRequirement: 0,
            customerDescription: customer.description || "",
            roundNumber: round
        };
    }

    return {
        customerDescription: customer.description || "",
        volumeRequirement: customer.rounds[index].volumeRequirement,
        roundNumber: round
    };
}

interface CustomerRoundInformation {
    volumeRequirement: number;
    customerDescription: string;
    roundNumber: number;
}
