import {GameStatusCompany, Round, RunningGameStatus} from "../../../../../../api";
import {bankersRound, getActualPercent} from "../../../../../../utils/mathUtils";
import {
    getCompanyBidsForGame,
    getCompanyNames,
    getMaximalRevenueProfitsForGame,
    getRoundStatsForMarketShare,
    LostValueType,
    MarketShareType,
    RoundStats
} from "../Components/DataTables/DataTables";
import {
    CompanyMarketProfit,
    MarketSortType,
    sortRowsBySortType
} from "../Components/RankingTables/RankingTables";

export function generateDownloadableStatsCsv(props: RunningGameStatus) {
    const roundHeadings = getRoundNumbersWithHeading(props.rounds);
    const averagePrices = getAveragePrices(props.rounds, props.name);

    const marketValueHeadings = getRoundNumbersWithHeading(
        props.rounds,
        `Market Share by Value - ${props.name}`
    );
    const marketShareByValue = getMarketShare(props, "Value");

    const marketVolumeHeadings = getRoundNumbersWithHeading(
        props.rounds,
        `Market Share by Volume - ${props.name}`
    );
    const marketShareByVolume = getMarketShare(props, "Volume");

    const valueLostHeadings = getRoundNumbersWithHeading(
        props.rounds,
        `Value list in ${props.name}`
    );
    const maximalValue = getValueLost(props, "MaxRevenue", "Maximal");
    const remainingValue = getValueLost(props, "Revenue", "Remaining");

    const marketingProfitHeading = getRoundNumbersWithHeading(props.rounds, "Marketing Profit");
    const marketProfit = getMarketingProfit(props);

    const averageMarketProfit = ["Average Market Profit"];
    const averageMarketTable = getMarketingProfitBasedOnType(props, "Average");

    const totalMarketProfit = ["Total Market Profit"];
    const totalMarketTable = getMarketingProfitBasedOnType(props, "Total");

    return [
        roundHeadings,
        averagePrices,
        "",
        marketValueHeadings,
        ...marketShareByValue,
        "",
        marketVolumeHeadings,
        ...marketShareByVolume,
        "",
        valueLostHeadings,
        maximalValue,
        remainingValue,
        "",
        marketingProfitHeading,
        ...marketProfit,
        "",
        averageMarketProfit,
        ...averageMarketTable,
        "",
        totalMarketProfit,
        ...totalMarketTable
    ];
}

function getRoundNumbersWithHeading(rounds: Round[], prefix?: string): string[] {
    const items: string[] = [prefix ? prefix : ""];

    for (const round of rounds) {
        items.push(`Round ${round.number}`);
    }

    return items;
}

function getAveragePrices(rounds: Round[], gameName: string): (number | string)[] {
    const items: (string | number)[] = [`Average Price - ${gameName}`];

    for (const round of rounds) {
        const averageBid = round.averageBid ? bankersRound(round.averageBid) : 100;
        items.push(averageBid);
    }

    return items;
}

function getMarketShare(props: RunningGameStatus, marketShareType: MarketShareType) {
    const items: any[] = [];
    for (const company of props.companies) {
        const roundStats = getRoundStatsForMarketShare(props, marketShareType, company);

        items.push(sortMarketShareRoundStatsToCsvFriendlyFormat(roundStats, company));
    }

    return items;
}
function sortMarketShareRoundStatsToCsvFriendlyFormat(
    stats: RoundStats[],
    company: GameStatusCompany
) {
    const items: (string | number)[] = [`${company.name}`];

    for (const stat of stats) {
        items.push(`${getActualPercent(stat.value)}%`);
    }

    return items;
}

function getValueLost(props: RunningGameStatus, type: LostValueType, label: string) {
    const maximalRevenue = getMaximalRevenueProfitsForGame(props, type);
    const items: (string | number)[] = [`${label} value`];

    for (const item of maximalRevenue) {
        items.push(item.value);
    }

    return items;
}

function getMarketingProfit(props: RunningGameStatus) {
    const companies = getCompanyNames(props.companies);
    const items: any[] = [];
    for (const company of companies) {
        const sortedRows = getCompanyBidsForGame(company, props.rounds);
        items.push(getMarketProfitRow(sortedRows, company));
    }

    return items;
}

function getMarketProfitRow(sortedRows: RoundStats[], company: string) {
    const items: (string | number)[] = [company];

    for (const item of sortedRows) {
        items.push(item.value);
    }

    return items;
}

function getMarketingProfitBasedOnType(props: RunningGameStatus, sortType: MarketSortType) {
    const companies = getCompanyNames(props.companies);
    const rows: CompanyMarketProfit[] = [];

    for (const company of companies) {
        const sortedRow = getCompanyBidsForGame(company, props.rounds);
        const total = sortedRow.reduce((a, b) => a + b.value, 0);
        rows.push({
            companyName: company,
            average: total / props.rounds.length,
            total
        });
    }

    return sortRowsBySortType(rows, sortType).map((item, index) => {
        return getMarketingProfitBasedOnTypeRow(index + 1, item, sortType);
    });
}

function getMarketingProfitBasedOnTypeRow(
    rank: number,
    props: CompanyMarketProfit,
    sortType: MarketSortType
) {
    const value = bankersRound(sortType === "Total" ? props.total : props.average);
    return [`Rank ${rank}`, props.companyName, value];
}
