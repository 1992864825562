import React from "react";
import {Bid} from "../../../../../../api";
import RoundTableHeader from "./RoundTableHeader";
import RoundTableRow from "./RoundTableRow";
import {RoundTableBase} from "./Helpers/roundTableHelpers";

const RoundTable = (props: RoundTableProps) => {
    return (
        <React.Fragment>
            {props.bids.length > 0 ? (
                <div className="pb-5 mt-3 ml-3 mr-3">
                    <div className="d-block table-overflow-x vi-table-wrapper">
                        <table className="vi-table fadeIn">
                            <RoundTableHeader
                                showPenaltyColumn={props.showPenaltyColumn}
                                showRankColumn={props.showRankColumn}
                                showMaxProfitColumn={props.showMaxProfitColumn}
                                showMaxRevenueColumn={props.showMaxRevenueColumn}
                                showCompanyColumn={props.showCompanyColumn}
                                showCustomerColumn={props.showCustomerColumn}
                            />
                            <tbody>
                                {sortBids(props.bids, props.showRankColumn).map(
                                    (bid: Bid, index) => {
                                        return (
                                            <RoundTableRow
                                                key={index}
                                                bid={bid}
                                                showRankColumn={props.showRankColumn}
                                                showPenaltyColumn={props.showPenaltyColumn}
                                                showMaxProfitColumn={props.showMaxProfitColumn}
                                                showMaxRevenueColumn={props.showMaxRevenueColumn}
                                                showCompanyColumn={props.showCompanyColumn}
                                                showCustomerColumn={props.showCustomerColumn}
                                            />
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">No bids have been made yet...</h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default RoundTable;

interface RoundTableProps extends RoundTableBase {
    bids: Bid[];
}

function sortBids(bids: Bid[], showRank: boolean) {
    if (!showRank) return bids;

    return bids.sort((a: Bid, b: Bid) => {
        if (!a.rank) return 0;
        if (!b.rank) return 0;

        return a.rank - b.rank;
    });
}
