import {
    StoreServiceData,
    StoreState
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createStoreState} from "../../helpers/storeHelpers";
import {GAME_SETTINGS_STORE} from "../../gameSettings/actions/GameSettingsActionTypes";

export const USER_STORE: StoreState = createStoreState("user");

interface UserLoading extends StoreServiceData<string> {
    type: typeof GAME_SETTINGS_STORE.LOADING;
}
interface UserError extends StoreServiceData<string> {
    type: typeof GAME_SETTINGS_STORE.ERROR;
}
interface UserSuccess extends StoreServiceData<string> {
    type: typeof GAME_SETTINGS_STORE.SUCCESS;
}

export type UserDispatchTypes = UserLoading | UserError | UserSuccess;
