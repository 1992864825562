import React, {useMemo} from "react";
import {RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import {bankersRound} from "../../../../../../../utils/mathUtils";
import {getValueOfItem} from "../DataTables/DataTables";
import {getRoundLabels} from "./ValueLostAreaChart";
import {defaultColours} from "../../../Components/InRound/PreviousRoundViews/PreviousRoundStats";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
} from "chart.js";
import {Line} from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "bottom" as const
        }
    },
    scales: {
        y: {
            min: 0,
            max: 100
        }
    }
};

const AveragePriceLineChart = (props: RunningGameStatus) => {
    const data = useMemo(() => convertToAveragePriceLineGraph(props), []);
    return (
        <React.Fragment>
            <FormHeader
                headerName={`Average Price`}
                headerType={"h4"}
                showRow
                rowClassName="mt-3"
            />
            <Line options={options} data={data} />
        </React.Fragment>
    );
};

export default AveragePriceLineChart;

function convertToAveragePriceLineGraph(props: RunningGameStatus): AveragePriceGraphProps {
    const labels = ["Start", ...getRoundLabels(props.numberRounds)];
    return {
        labels,
        datasets: getAveragePrice(props)
    };
}

function getAveragePrice({rounds}: RunningGameStatus): AveragePriceProps[] {
    return [
        {
            label: "Average Price",
            data: [100, ...rounds.map((round) => bankersRound(getValueOfItem(round.averageBid)))],
            borderColor: defaultColours[1],
            backgroundColor: defaultColours[1]
        }
    ];
}

interface AveragePriceGraphProps {
    labels: string[];
    datasets: AveragePriceProps[];
}

interface AveragePriceProps {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
}
