import React from "react";

const GamePerRoundSettingsHeader = () => (
    <thead>
        <tr className="vi-table-header">
            <th align="center" className="vi-table-header-item mw-20 p-3">
                Round Number
            </th>
            <th align="center" className="vi-table-header-item mw-20 p-3">
                Round Duration (Minutes)
            </th>
        </tr>
    </thead>
);

export default GamePerRoundSettingsHeader;
