import React, {useMemo} from "react";
import FormHeader from "../../../../../../Form/FormHeader";
import {
    ChartProps,
    getMarketShareForBarCharts,
    MarketShareBarChartProps,
    MarketShareDatum
} from "../../../CompleteGame/Components/Statistics/MarketShareBarChart";
import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PreviousRoundStats = (props: PreviousRoundStatsProps) => {
    const data = useMemo(() => getBarChartDataForPreviousRound(props), []);
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Market Share (%) - ${props.marketShareType} (Round ${props.index})`}
                headerType={"h5"}
                showRow
            />
            <Bar options={options} data={data} />
        </React.Fragment>
    );
};

export default PreviousRoundStats;

interface PreviousRoundStatsProps extends MarketShareBarChartProps {
    index: number;
}

interface PreviousRoundGraphProps {
    labels: string[];
    datasets: PreviousRoundProps[];
}

interface PreviousRoundProps {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
}

function getBarChartDataForPreviousRound(props: PreviousRoundStatsProps): PreviousRoundGraphProps {
    const marketShares = getMarketShareForBarCharts(props);
    return {
        labels: [`Round ${props.index}`],
        datasets: marketShares.map((share, index) => {
            return {
                label: share.label,
                borderColor: defaultColours[index],
                backgroundColor: defaultColours[index],
                data: getValueOfShare(share, `Round ${props.index}`)
            };
        })
    };
}

function getValueOfShare(share: ChartProps<MarketShareDatum>, round: string): number[] {
    const index = share.data.findIndex((item) => item.round === round);
    return [share.data[index].share];
}

export const defaultColours = ["#bc8403", "#358192", "#868461", "#b93232", "#112a39"];

const options = {
    indexAxis: "y" as const,
    elements: {
        bar: {
            borderWidth: 2
        }
    },
    responsive: true,
    plugins: {
        legend: {
            position: "bottom" as const
        },
        title: {
            display: false,
            text: "Chart.js Horizontal Bar Chart"
        }
    },
    scales: {
        x: {
            min: 0,
            max: 100
        }
    }
};
