import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import EditGameSettingsForm from "./Components/EditGameSettingsForm";
import WithServiceState from "../../../modules/storeFetchWrappers/components/WithServiceState";
import {RootStore} from "../../../store/Store";
import {useParams} from "react-router-dom";
import {fetchGameById} from "../../../store/gameSettings/actions/GameSettingsActions";

const ServiceWrapper = WithServiceState(EditGameSettingsForm);

const CopyGameSettings = () => {
    const dispatch = useDispatch();
    const gameSettingsStore = useSelector((state: RootStore) => state.gameSettings);
    const params: any = useParams();
    useEffect(() => {
        dispatch(fetchGameById(+params.gameIdToCopy));
    }, []);

    return (
        <React.Fragment>
            <ServiceWrapper showErrorMessage={false} {...gameSettingsStore} />
        </React.Fragment>
    );
};

export default CopyGameSettings;
